import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ClipboardService } from '@services/clipboard.service';
import { CompareService } from '@services/compare.service';
import { ConfigService } from '@services/config.service';
import { VehicleListing } from '@models/vehicle-listing';
import { ListingClick } from '@models/listing-click';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-vehicle-top-bar-search',
  templateUrl: './vehicle-top-bar-search.component.html',
  styleUrls: ['./vehicle-top-bar-search.component.sass']
})
export class VehicleTopBarSearchComponent implements OnInit {

  @Input() listing!: VehicleListing;

  @Output() compareView: EventEmitter<string> = new EventEmitter<string>();
  @Output() listingView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();

  constructor(
    public compareService: CompareService,
    private clipboardService: ClipboardService,
    private configService: ConfigService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  addToCompare() {
    this.compareService.toggleVehicle(this.listing);
  }

  copyLinkToListing($event: MouseEvent) {
    const routerCommands = ['/listing', this.listing.classifiedAdID];
    const url = this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + this.router.createUrlTree(routerCommands).toString();
    const successMsg = 'Link to <strong>' + this.listing.make + ' ' + this.listing.model + '</strong> copied';
    this.clipboardService.copy(url, successMsg, $event);
  }

  compare() {
    this.compareView.emit(this.listing.classifiedAdID);
  }

  viewListing(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.listingView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

}
