import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-price-rating',
  templateUrl: './price-rating.component.html',
  styleUrls: ['./price-rating.component.sass']
})
export class PriceRatingComponent implements OnInit {

  @Input() rating!: string;

  ngOnInit() {
  }

}
