import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject, Subject } from "rxjs";

import {
  AutoXpressLoanTermsPair,
  AutoXpressRateService,
  PaymentService,
  PaymentParameters,
  Rate,
  UserInputRateService,
  CreditScoreTableRateService,
  CreditTier,
  RateRow,
} from "@services/payment.service";
import { CustomFilter } from "@models/vehicle-listing";
import { environment } from "@environments/environment";

export interface Config {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  useTrueCarLeadDelivery: boolean;
  defaultRate: number;
  lowestRate: number;
  defaultTerm: number;
  defaultDownpayment: number;
  defaultTransactionFees: number;
  salesTaxRate: number;
  loanAppUrl: string;
  defaultSearchRadius: number;
  resultsPerPage: number;
  allowSalvageSearch: boolean;
  creditUnion: CreditUnion;
  preferredDealerText: string;
  showSignUpModal: boolean;
  showApplyBtn: boolean;
  sweepstakes?: Sweepstakes;
  balloonLoanOptions?: BalloonLoanOptions;
  autoXpressLoanTerms?: AutoXpressLoanTermsPair;
  productInformation?: ProductInformation;
  vehicleHistoryRequestConfig?: VehicleHistoryRequestConfig;
  virtualCarSaleConfig?: VirtualCarSaleConfig;
  virtualCarSaleParticipantText: string;
  loanDepartmentPhone: string;
  customPaymentDisclaimer?: string;
  customFilters: Record<string, CustomFilter>;
  genericAdImage?: string;
  genericAdLink?: string;
}

export interface ProductInformation {
  gap?: { name?: string; educationVerbiage?: string; link?: string, enabledByDefault: boolean };
  dp?: { name?: string; educationVerbiage?: string; link?: string, enabledByDefault: boolean };
  dp2?: { name?: string; educationVerbiage?: string; link?: string, enabledByDefault: boolean };
  vsc?: { name?: string; educationVerbiage?: string; link?: string, enabledByDefault: boolean };
}

export interface VehicleHistoryRequestConfig {
  reportName: string;
  verbiage?: string;
}

export interface BalloonLoanOptions {
  programName: string;
  terms: Array<number>;
  rateRows: Array<RateRow>;
  creditTiers: Array<CreditTier>;
  defaultCreditTierId: number;
  loanFee: number;
}

export interface Sweepstakes {
  id: number;
  prize: string;
  prizeDetailUrl: string | undefined;
  prizeImgUrl: string;
  rulesUrl: string;
}

export interface VirtualCarSaleConfig {
  id: number;
  description: string;
  learnMoreURL?: string;
  headerText?: string;
  landingPageStyle: number;
  disclaimer?: string;
  vcsAdImage?: string;
  vcsAdLink?: string;
  registration: boolean;
  filters?: VCSFilters;
  confetti: boolean;
}

export interface VCSFilters {
  maxMileage?: number;
  minPrice?: number;
  maxPrice?: number;
  oldestModelYear?: number;
  onlyParticipatingDealers: boolean;
  bodyStyles: string[];
  makes: string[];
}

// Key guard function for VCSFilters
export function isKeyOfVCSFilters(key: string, obj: VCSFilters | undefined): key is keyof VCSFilters {
  return obj === undefined ? false : key in obj;
}

export interface CreditUnion {
  shortName: string;
  name: string;
  logoUrl: string;
  autoLinkPageUrl: string;
  unfinanceableVehicleTypes: string[];
}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  cu!: CreditUnion;
  showSignUpModal!: boolean;
  showApplyBtn!: boolean;
  sweepstakes!: Sweepstakes;
  preferredDealerText!: string;
  defaultRate!: number;
  lowestRate!: number;
  availableLoanTerms!: Array<number>;
  balloonLoanFee!: number;
  balloonLoanProgramName!: string;
  loanAppUrl$: Subject<string> = new ReplaySubject<string>();
  defaultSearchRadius$: Subject<number> = new ReplaySubject<number>();
  resultsPerPage!: number;
  allowSalvageSearch$: Subject<boolean> = new ReplaySubject<boolean>();
  productInformation?: ProductInformation;
  vehicleHistoryRequestConfig?: VehicleHistoryRequestConfig;
  virtualCarSaleParticipantText!: string;
  virtualCarSaleConfig?: VirtualCarSaleConfig;
  loanDepartmentPhone!: string;
  useTrueCarLeadDelivery!: boolean;
  unfinanceableVehicleTypes: Array<string> = [];
  customPaymentDisclaimer?: string;
  customFilters: Record<string, CustomFilter> = {};
  genericAdImage?: string;
  genericAdLink?: string;

  private readonly configUrl = environment.apiBaseUrl + "/v2/cbs/:cu/config";
  private configLoaded = false;

  // We use a hack to pass data from the parent window into this iframe
  // The data is sent through the name attribute on the outer iframe, which becomes window.name here.
  // The normal ways to data pass include:
  // (1) Setting document.domain to the same domain on both parent and child, then the child can directly access
  //     attributes set on the iframe node in the parent. Problem: document.domain is deprecated and will be removed
  //     from many browsers soon.
  // (2) Using postMessage. Problem: this introduces timing issues because the messages cannot be sent by the parent
  //     until the child is ready to receive them, but we need the data to be available before the child is fully loaded.
  // (3) Using the URL (e.g., with ? query strings or # hashtag strings). Problem: our system is built to use the
  //     URL parameters in unique ways, due to how we keep the parent window's hashtag in sync with the internal URLs
  //     of the iframe. Introducing extra parameters in the URL complicates this.
  // Solution: we use the window.name to pass data.
  private parentWindowArguments = JSON.parse(window.name);

  constructor(private http: HttpClient, private paymentService: PaymentService) {}

  get cuShortName(): string {
    const cu = this.parentWindowArguments["cu"];
    if (cu) {
      return cu;
    } else {
      throw "Fatal error: missing cu data in window name!";
    }
  }

  get defaultZip(): string {
    const zip = this.parentWindowArguments["zip"];
    if (zip) {
      return zip;
    } else {
      throw "Fatal error: missing zip data in window name!";
    }
  }

  financingSupported(vehicleType: string): boolean {
    return !this.unfinanceableVehicleTypes.includes(vehicleType);
  }

  loadConfig() {
    if (!this.configLoaded) {
      this.configLoaded = true;

      this.http.get<Config>(this.configUrl.replace(":cu", this.cuShortName)).subscribe((config) => {
        // Set CU
        this.cu = { ...config.creditUnion };

        // Set loan department phone number
        this.loanDepartmentPhone = config.loanDepartmentPhone;

        // Set the TrueCar lead delivery setting
        this.useTrueCarLeadDelivery = config.useTrueCarLeadDelivery;

        // Set custom payment disclaimer, if there is one
        if (config.customPaymentDisclaimer) {
          this.customPaymentDisclaimer = config.customPaymentDisclaimer;
        }

        // Set the unfinanceable vehicles
        this.unfinanceableVehicleTypes = [ ...config.creditUnion.unfinanceableVehicleTypes ];

        // Set sweepstakes setting
        if (config.sweepstakes) {
          this.sweepstakes = { ...config.sweepstakes };
        }

        // Set the virtual car sale settings
        this.virtualCarSaleConfig = config.virtualCarSaleConfig;
        this.virtualCarSaleParticipantText = config.virtualCarSaleParticipantText;

        // Set signup modal and apply button display settings
        this.showSignUpModal = config.showSignUpModal;
        this.showApplyBtn = config.showApplyBtn;

        // Set advertising/promotional images and links if available
        if (config.genericAdImage) {
          this.genericAdImage = config.genericAdImage;
          if (config.genericAdLink) {
            this.genericAdLink = config.genericAdLink;
          }
        }

        // Set custom filters
        this.customFilters = config.customFilters;

        // Set preferred dealer text
        if (config.preferredDealerText) {
          this.preferredDealerText = config.preferredDealerText;
        }

        // Set colors
        const newStyle = document.createElement("style");
        newStyle.innerHTML +=
          ".btn-primary {background-color: " +
          config.primaryColor +
          "; border-color: " +
          config.primaryColor +
          "}";
        
        newStyle.innerHTML +=
          ".l2-pp-getapproved-box {border-color: " +
          config.primaryColor +
          ";} .tab-header .tab-btn.active {background-color: " +
          config.primaryColor + " !important;}" +
          ".triangle-tab {border-top: 10px solid " +
          config.primaryColor + ";}" +
          ".l2-pp-btn-s2 {border-color: " +
          config.primaryColor + ";}" +
          ".l2-pp-btn-s3 {border-color: " +
          config.primaryColor + ";}" +
          ".tab-content {border: 3px solid " +
          config.primaryColor + ";}" +
          ".price-rating-gradient1 {stop-color: " + 
          config.primaryColor + ";}" +
          ".price-rating-gradient2 {stop-color: " + 
          config.secondaryColor + ";}" +
          ".l2-search {background-color: " + 
          config.primaryColor + " !important;}" +
          ".btn-dark:hover {color: " + 
          config.tertiaryColor + " !important;}" +
          ".search-btnicon {background-color: " + 
          config.primaryColor + " !important;}"  +
          ".l2-labels div app-save-vehicle-button div:hover {color: #fff600 !important;}" +
          ".l2-search-col12 .l2-btn-dark:hover {background-color: " + 
          config.tertiaryColor + " !important;}" +
          ".l2-search-col12 .l2-btn-outline:hover {background-color: " + 
          config.secondaryColor + " !important;}" +
          ".l2-search-col12 .l2-btn-prim {background-color: " + 
          config.primaryColor + " !important;}" +
          ".l2-search-col12 .l2-btn-darkoutline {border-color: " + 
          config.primaryColor + " !important;}" +
          ".l2-search-dealer {background-color: " + 
          config.primaryColor + " !important;}" +
          ".l2-searchbar-icon {background-color: " + 
          config.primaryColor + " !important;}" +
          ".l2-account-btn button {background-color: " + 
          config.primaryColor + " !important;}" +
          ".tab-content-mob {border: 3px solid" + 
          config.primaryColor + " !important;}" +
          ".tab-btn:hover {color:#fff; background-color:" + 
          config.primaryColor + " !important;}" +
          ".l2-vdp-getapproved-box {border-color:" + 
          config.primaryColor + " !important;}";


        newStyle.innerHTML +=
          ".btn-primary.disabled, .btn-primary:disabled {background-color: " +
          config.primaryColor +
          "; border-color: " +
          config.primaryColor +
          "}";
        newStyle.innerHTML +=
          ".btn-outline-primary {color: " +
          config.primaryColor +
          "; border-color: " +
          config.primaryColor +
          "}";
        newStyle.innerHTML += ".badge-primary {background-color: " + config.primaryColor + "}";
        newStyle.innerHTML +=
          ".custom-control-input:checked~.custom-control-label::before {background-color: " +
          config.primaryColor +
          "; border-color: " +
          config.primaryColor +
          "}";
        newStyle.innerHTML +=
          ".bg-primary {background-color: " + config.primaryColor + " !important}";
        newStyle.innerHTML +=
          ".border-primary {border-color: " + config.primaryColor + " !important}";
        newStyle.innerHTML += ".text-primary {color: " + config.primaryColor + "!important}";
        window.document.body.appendChild(newStyle);


        // Set the available terms to the default (to be overridden by custom balloon loan options if those are available)
        this.availableLoanTerms = [12, 24, 36, 48, 60, 72, 84];

        // Set the default interest rate, sales tax rate, and balloon loan options for the payment calculator
        // Override the default loan terms with the client's available loan terms
        let newPaymentParameters: PaymentParameters;
        // This will copy the current payment parameters, which may either be the default parameters or the
        // parameters which were restored from the user session.
        newPaymentParameters = { ...this.paymentService.params$.value };
        if (config.autoXpressLoanTerms) {
          newPaymentParameters.otherDownPayment = config.autoXpressLoanTerms.newTerms
            ? config.autoXpressLoanTerms.newTerms.downPayment
            : config.autoXpressLoanTerms.usedTerms
            ? config.autoXpressLoanTerms.usedTerms.downPayment
            : newPaymentParameters.otherDownPayment;

          newPaymentParameters.preferredTermMonths = config.autoXpressLoanTerms.newTerms
            ? config.autoXpressLoanTerms.newTerms.defaultTermLength
            : config.autoXpressLoanTerms.usedTerms
            ? config.autoXpressLoanTerms.usedTerms.defaultTermLength
            : newPaymentParameters.preferredTermMonths;

          this.availableLoanTerms = config.autoXpressLoanTerms.newTerms
            ? config.autoXpressLoanTerms.newTerms.termOptions.map((t) => t.termLength)
            : config.autoXpressLoanTerms.usedTerms
            ? config.autoXpressLoanTerms.usedTerms.termOptions.map((t) => t.termLength)
            : this.availableLoanTerms;
          this.availableLoanTerms.sort();

          const autoXpressRateService = new AutoXpressRateService(
            config.defaultRate,
            config.autoXpressLoanTerms.newTerms
              ? config.autoXpressLoanTerms.newTerms.termOptions
              : undefined,
            config.autoXpressLoanTerms.usedTerms
              ? config.autoXpressLoanTerms.usedTerms.termOptions
              : undefined
          );
          newPaymentParameters.rateService = autoXpressRateService;
        } else if (config.balloonLoanOptions) {
          this.balloonLoanFee = config.balloonLoanOptions.loanFee;
          this.balloonLoanProgramName = config.balloonLoanOptions.programName;
          this.availableLoanTerms = [...config.balloonLoanOptions.terms];

          // Use the default credit tier unless the user has previously selected a different tier
          const newCreditTierScore = (() => {
            switch (newPaymentParameters.rateService.kind) {
              case "userInput":
              case "autoXpress":
                const defaultCreditTier = config.balloonLoanOptions.creditTiers.find((ct) => {
                  return config.balloonLoanOptions === undefined // can never happen, but that isn't obvious to the compiler
                    ? -1
                    : ct.id === config.balloonLoanOptions.defaultCreditTierId;
                });
                if (defaultCreditTier === undefined) {
                  // This should not happen, but if it does, pick the first credit tier instead
                  if (config.balloonLoanOptions.creditTiers[0]) {
                    return config.balloonLoanOptions.creditTiers[0].minimumCreditScore;
                  } else {
                    // This should really never happen, but if it does, we'll just use the maximum credit score
                    return 850;
                  }
                } else {
                  return defaultCreditTier.minimumCreditScore;
                }
              case "creditScoreTable":
                return newPaymentParameters.rateService.currentCreditTierScore;
              default:
                const _exhaustiveCheck: never = newPaymentParameters.rateService;
                return _exhaustiveCheck;
            }
          })();

          const fallbackInterestRate = (() => {
            switch (newPaymentParameters.rateService.kind) {
              case "userInput":
                return config.defaultRate;
              case "autoXpress":
              case "creditScoreTable":
                return newPaymentParameters.rateService.fallbackInterestRate;
            }
          })();

          const creditScoreTableRateService = new CreditScoreTableRateService(
            fallbackInterestRate,
            newCreditTierScore,
            config.balloonLoanOptions.creditTiers,
            false, // isBalloonLoan
            config.balloonLoanOptions.rateRows,
            config.balloonLoanOptions.terms
          );
          newPaymentParameters.rateService = creditScoreTableRateService;
        } else {
          // A negative number means that no default interest rate is set for the CU
          const rateChangedinConfig = config.defaultRate >= 0;
          // Do not override the rate if the payment service is not using the default rate (which would indicate that the
          // user has already changed the rate or it has been loaded in from their user session)
          const currentInterestRate = newPaymentParameters.rateService.getRate(
            false,
            this.paymentService.currentYear,
            newPaymentParameters.preferredTermMonths
          ).interestRate;
          const defaultInterestRate = this.paymentService.defaultParameters.rateService.getRate(
            false,
            this.paymentService.currentYear,
            newPaymentParameters.preferredTermMonths
          ).interestRate;
          const currentInterestRateSameAsDefault = currentInterestRate === defaultInterestRate;
          if (rateChangedinConfig && currentInterestRateSameAsDefault) {
            newPaymentParameters.rateService = new UserInputRateService(config.defaultRate);
          }
        }

        // Set the default term, downpayment, transaction fees, and sales tax rate for the payment calculator
        // In the if statements that follow, we only override the default if the current value that the payment service is using
        // matches the *payment service's* default value. The reason for this is that both the config service and the user session
        // service may be loading loan settings at the same time (the latter if the user has previously accessed the system and had
        // their loan settings saved to their session). Generally, the user session service will finish loading loan settings first,
        // as no network request is required to get those settings. We do not want the config service to override any changed settings
        // that the user has had persisted to their session. And we can tell if the user session service has updated the payment service
        // with loan settings that the user has changed by comparing the values that the payment service currently has to the default
        // values which the payment service uses (before modified by the config or user session service).
        if (
          this.paymentService.params$.value.salesTaxRate ===
          this.paymentService.defaultParameters.salesTaxRate
        ) {
          newPaymentParameters.salesTaxRate = config.salesTaxRate;
        }
        if (
          this.paymentService.params$.value.transactionFees ===
          this.paymentService.defaultParameters.transactionFees
        ) {
          newPaymentParameters.transactionFees = config.defaultTransactionFees;
        }
        // If we received AutoXpressLoanTerms, do not use the client's manually configured down payment and term as custom values were set above
        if (!config.autoXpressLoanTerms) {
          if (
            this.paymentService.params$.value.otherDownPayment ===
            this.paymentService.defaultParameters.otherDownPayment
          ) {
            newPaymentParameters.otherDownPayment = config.defaultDownpayment;
          }
          if (
            this.paymentService.params$.value.preferredTermMonths ===
            this.paymentService.defaultParameters.preferredTermMonths
          ) {
            newPaymentParameters.preferredTermMonths = config.defaultTerm;
          }
        }

        // Also store the default and lowest rates in the config service, so the former can be used as the default
        // in calculating monthly payments and the latter can be shown on the site as the "lowest rate".
        this.defaultRate = config.defaultRate;
        this.lowestRate = config.lowestRate;

        if (config.productInformation) {
          if (config.productInformation.gap && config.productInformation.gap.enabledByDefault) {
            newPaymentParameters.productsEnabled.add("GAP");
          }
          if (config.productInformation.dp && config.productInformation.dp.enabledByDefault) {
            newPaymentParameters.productsEnabled.add("DP1");
          }
          if (config.productInformation.vsc && config.productInformation.vsc.enabledByDefault) {
            newPaymentParameters.productsEnabled.add("VSC");
          }
          this.productInformation = { ...config.productInformation };
        }

        // Store the vehicle report history settings
        if (config.vehicleHistoryRequestConfig) {
          this.vehicleHistoryRequestConfig = { ...config.vehicleHistoryRequestConfig };
        }

        // Send the new parameters to the payment service so they will be used by the rest of the systems
        this.paymentService.params$.next(newPaymentParameters);

        // Other configuration items
        this.loanAppUrl$.next(config.loanAppUrl);
        this.defaultSearchRadius$.next(config.defaultSearchRadius);
        this.resultsPerPage = config.resultsPerPage;
        this.allowSalvageSearch$.next(config.allowSalvageSearch);
      });
    }
  }
}
function generateLinearGradient() {
  throw new Error("Function not implemented.");
}

