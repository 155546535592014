import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ProductType } from '@models/vehicle-listing';

export interface PaymentParameters {
  rateService: UserInputRateService | CreditScoreTableRateService | AutoXpressRateService;
  preferredTermMonths: number;
  tradeInValue: number;
  otherDownPayment: number;
  tradeInSalesTaxCredit: boolean;
  salesTaxRate: number;
  transactionFees: number;
  productsEnabled: Set<ProductType>;
}

export function isPaymentParameters(input: any): input is PaymentParameters {
  return input !== null && typeof input === 'object'
    && 'preferredTermMonths' in input && typeof input.preferredTermMonths === 'number'
    && 'tradeInValue' in input && typeof input.tradeInValue === 'number'
    && 'otherDownPayment' in input && typeof input.otherDownPayment === 'number'
    && 'tradeInSalesTaxCredit' in input && typeof input.tradeInSalesTaxCredit === 'boolean'
    && 'salesTaxRate' in input && typeof input.salesTaxRate === 'number'
    && 'transactionFees' in input && typeof input.transactionFees === 'number';
}



export interface RateService {
  // Note: these strings should match the corresponding strings used on the server-side JSON transformers
  kind: "userInput" | "creditScoreTable" | "autoXpress";
  getRate(isNew: boolean, modelYear: number, termMonths: number): Rate;
}



export class UserInputRateService implements RateService {
  kind: "userInput" = "userInput";

  constructor(public interestRate: number) {}

  // A "userInput" kind of RateService always returns the same rate, based on the user input, regardless of vehicle
  getRate(isNew: boolean, modelYear: number = 0, termMonths: number): Rate {
    return {
      isFallbackRate: true,
      interestRate: this.interestRate,
    };
  }
}



export class AutoXpressRateService implements RateService {
  kind: "autoXpress" = "autoXpress";

  private _highestRateMemoized: Map<boolean, number> = new Map(); // isNew to interest rate

  constructor(
    public fallbackInterestRate: number, // the interest rate to use if no rate is available for the specified vehicle
    public newTerms?: Array<AutoXpressTermOption>,
    public usedTerms?: Array<AutoXpressTermOption>,
  ) {}

  getRate(isNew: boolean, modelYear: number = 0, termMonths: number): Rate {
    const terms = isNew ? this.newTerms : this.usedTerms;

    if (terms === undefined) {
      return {
        isFallbackRate: true,
        interestRate: this.fallbackInterestRate,
      };;
    } else {
      const matchingTerm = terms.find(termOption => termOption.termLength === termMonths);

      if (matchingTerm === undefined) {
        // We could not find a rate for a vehicle of this type for the requested term. Instead, we will use the fallback rate.
        return {
          isFallbackRate: true,
          interestRate: this.fallbackInterestRate,
        };
      } else {
        return {
          isFallbackRate: false,
          interestRate: matchingTerm.interestRate,
        };
      }
    }
  }
}

export interface AutoXpressTermOption {
  termLength: number,
  interestRate: number,
  creditLimit: number,
}

export interface AutoXpressLoanTerms {
  termOptions: Array<AutoXpressTermOption>,
  offerId: String,
  downPayment: number,
  defaultTermLength: number,
  rateType: String,
}

export interface  AutoXpressLoanTermsPair {
  newTerms?: AutoXpressLoanTerms,
  usedTerms?: AutoXpressLoanTerms,
}



export class CreditScoreTableRateService implements RateService {
  kind: "creditScoreTable" = "creditScoreTable";

  private _creditTierScores: Array<number>;
  private readonly currentYear = new Date().getFullYear();
  private _highestRateMemoized: Map<string, number> = new Map(); // creditTierId-term to interest rate
  private _rateMemorized: Map<string, Rate> = new Map();

  constructor(
    public fallbackInterestRate: number,   // the interest rate to use if no rate is available for the specified vehicle
    public currentCreditTierScore: number, // public because the user can update which credit tier they want to use
    public creditTiers: Array<CreditTier>, // public because it is used to populate the credit score <select>
    public isBalloonLoan: boolean,         // used internally to decide which type of rate to use (balloon or conventional)
    public rateRows: Array<RateRow>,       // used internally to look up the best rate for any given vehicle
    public availableTerms: Array<number>,  // used internally to pick alternative term lengths if the preferred term length is not available
  ) {
    // Save the distinct minimum credit scores
    this._creditTierScores = creditTiers.map(ct => ct.minimumCreditScore).filter((score, idx, arr) => arr.indexOf(score) === idx).sort().reverse();
  }

  get creditTierScores(): Array<number> {
    return this._creditTierScores;
  }

  // AFG sends us two lists: the credit tiers that the client has configured and a separate list of the rates associated with each
  // credit tier / max vehicle age / max term tuple. This means that, given a particular user credit score, we need to
  // start by looking up the corresponding credit tier from the first list, then check the second list to find the best rate that
  // matches that credit tier plus the the vehicle age and the term length that the user has selected.

  // A "creditScoreTable" kind of RateService returns a rate based on looking up the rate in a credit score table
  getRate(isNew: boolean, modelYear: number = 0, termMonths: number): Rate {
    const memoizeKey = this.currentCreditTierScore + "-" + isNew + "-" + modelYear + "-" + termMonths;
    const memoizedRate = this._rateMemorized.get(memoizeKey);
    if (memoizedRate !== undefined) {
      return memoizedRate;
    } else {
      // Step 1: find the rate row that matches the currently selected loan term and vehicle age
      const matchingRatesRowByTerm = this.rateRows.filter(rateRow => {
        // Filter out any entries below the selected term
        return rateRow.maxTerm >= termMonths;
      });

      // Step 2: now filter out any rates that only apply to vehicles newer than the current vehicle
      const matchingRateRowsByTermAndAge = matchingRatesRowByTerm.filter(rateRow => {
        return rateRow.maxVehicleAge >= (this.currentYear - modelYear);
      });

      // Step 3: check each matching rate row for specific interest rates for the current creditTierScore
      const currentCreditTierId = this.getCurrentCreditTierId(isNew);
      const matchingRateSets = matchingRateRowsByTermAndAge.map(rateRow => {
        return rateRow.ratesByCreditTierId[currentCreditTierId.toString()];
        // ^Yes, confusingly the keys to the ratesByCreditTierId are strings instead of numbers
      }).filter(rateSet => {
        // The rateSet may be undefined if the current credit tier ID was not found in this rateRow
        const isDefined = rateSet !== undefined

        // There may not be a specific rate here if the client doesn't support either balloon or conventional
        // loans for this particular model-year/loan-term. We have seen cases were the credit tier ID is defined
        // but it doesn't contain any defined rates, so we have to explicitly check this here.
        let hasRate = false;
        if (isDefined) {
          if (this.isBalloonLoan) {
            hasRate = rateSet.balloonRate !== undefined;
          } else {
            hasRate = rateSet.conventionalRate !== undefined;
          }
        }

        // Only use this rateSet if it has a valid rate
        return isDefined && hasRate;
      }) as Array<RateSet>; // we have to manually cast from Array<RateSet | undefined> to Array<RateSet>

      // Step 4: If there is more than one match, pick the lowest one available
      let sortedRateSets;
      if (this.isBalloonLoan) {
        sortedRateSets = matchingRateSets.sort((a, b) => this.sortAscending(a.balloonRate, b.balloonRate));
      } else {
        sortedRateSets = matchingRateSets.sort((a, b) => this.sortAscending(a.conventionalRate, b.conventionalRate));
      }

      // Step 5: Return the best rate if available. If not available, this means the requested term is not applicable to this modelYear.
      // In that case, we will use the fallback rate.
      let interestRate;
      if (sortedRateSets.length > 0) {
        const selectedRateSet = sortedRateSets[0];

        if (this.isBalloonLoan) {
          interestRate = selectedRateSet.balloonRate;
        } else {
          interestRate = selectedRateSet.conventionalRate;
        }
      }

      let rate: Rate;
      if (interestRate === undefined || interestRate <= 0 || interestRate >= 100) {
        // The <= 0 and >= 100 checks are to exclude bad rate data
        // (Which we observed in production for PA Central FCU)

        // Go back to using the fallback rate
        rate = {isFallbackRate: true, interestRate: this.fallbackInterestRate};
      } else {
        // We successfully found a true interest rate from the rate table
        rate = {isFallbackRate: false, interestRate: interestRate};
      }

      this._rateMemorized.set(memoizeKey, rate);
      return rate;
    }
  }

  getCurrentCreditTierId(isNew: boolean): number {
    const matchingCreditTiers = this.creditTiers.filter(ct => ct.minimumCreditScore === this.currentCreditTierScore);

    const conditionedTier = isNew
                            ? matchingCreditTiers.filter(ct => ct.name.toLowerCase().indexOf("new") !== -1)
                            : matchingCreditTiers.filter(ct => ct.name.toLowerCase().indexOf("used") !== -1);

    return conditionedTier.length > 0
           ? conditionedTier[0].id
           : matchingCreditTiers[0].id;
  }

  // Handles sorting numbers in ascending order when either number may be undefined
  // Undefined values are sorted to the end
  private sortAscending(a: number | undefined, b: number | undefined) {
    if (a === undefined && b === undefined) {
      return 0;
    } else if (a === undefined) {
      return 1;
    } else if (b === undefined) {
      return -1;
    } else {
      return a - b;
    }
  }

}

export interface CreditTier {
  id: number;
  minimumCreditScore: number;
  name: string;
}

export interface RateRow {
  maxTerm: number;
  maxVehicleAge: number;
  ratesByCreditTierId: { [key: string]: RateSet };
}

export interface RateSet {
  conventionalRate: number | undefined;
  balloonRate: number | undefined;
}



export interface Rate {
  isFallbackRate: boolean;
  interestRate: number;
}



export interface PaymentCalculationResult {
  isFallbackRate: boolean;
  amount: number;
  rate: Rate;
  apr: number;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  readonly currentYear = new Date().getFullYear();

  readonly defaultParameters: PaymentParameters = {
    rateService: new UserInputRateService(4.49),
    preferredTermMonths: 60,
    tradeInValue: 0,
    otherDownPayment: 1000,
    tradeInSalesTaxCredit: true,
    salesTaxRate: 0,
    transactionFees: 750,
    productsEnabled: new Set<ProductType>(),
  };

  readonly params$: BehaviorSubject<PaymentParameters>;

  constructor() {
    this.params$ = new BehaviorSubject(this.defaultParameters);
  }

  calculatePayment(price: number, fixedPriceProductSales: number, isNew: boolean, modelYear: number = 0, endingBalance: number = 0, principleBasedMonthlyRatePerMillion: number = 0, balloonLoanOriginationFee: number = 0): PaymentCalculationResult {
    const paymentParams = this.params$.value;

    const taxableAmount = paymentParams.tradeInSalesTaxCredit
                          ? price - paymentParams.tradeInValue
                          : price;

    // If we do not subtract the trade-in value from the price, then we need to add it to the down payment
    const downPayment = paymentParams.tradeInSalesTaxCredit
                        ? paymentParams.otherDownPayment
                        : paymentParams.otherDownPayment + paymentParams.tradeInValue;

    const financedAmount = paymentParams.transactionFees + taxableAmount * (1 + paymentParams.salesTaxRate / 100) + fixedPriceProductSales - downPayment + balloonLoanOriginationFee;

    const rate = paymentParams.rateService.getRate(isNew, modelYear, paymentParams.preferredTermMonths);

    const monthlyRate = rate.interestRate / 100 / 12;

    // A positive ending balance means this is a balloon loan.
    // The balloon loan payments are calculated to pay off the loan, less the adjusted residual amount, one period prior
    // to the end of the term. The residual amount is adjusted to the then-present value at one period prior to the end of the term.
    // This allows the borrower to turn in the collateral *during* that last period in order for the collateral to pay off the loan
    // at the end of the period.
    const adjustedEndingBalance = endingBalance > 0
                                  ? endingBalance / (1 + monthlyRate)
                                  : 0;

    const adjustedTerm = endingBalance > 0
                         ? paymentParams.preferredTermMonths - 1
                         : paymentParams.preferredTermMonths;

    // Note: we do not support principal-based monthly-rate product types (PrincipalBasedMonthlyRateProductPrice) for AFG.
    // To add support, we need to work with AFG to be sure they can support the different calculation method needed on their end.
    const paymentAmount = financedAmount < 0 || adjustedEndingBalance > financedAmount
                          ? 0 // Nothing is being financed (e.g., cash payment)
                          : endingBalance > 0 // Is this a balloon loan calculation
                            // Yes, balloon loan
                            ? monthlyRate === 0
                              ? financedAmount / adjustedTerm // avoid divide by zero
                              : (financedAmount * monthlyRate - adjustedEndingBalance * monthlyRate) / (Math.pow(1 + monthlyRate, adjustedTerm) - 1) + financedAmount * monthlyRate
                            // No, not balloon load (supports principal based monthly rate products)
                            : principleBasedMonthlyRatePerMillion === 0 && monthlyRate === 0
                              ? financedAmount / adjustedTerm // avoid divide by zero
                              // See the comment in the scala-side CarBuyingService file for an explanation of this
                              : -((principleBasedMonthlyRatePerMillion / 1000000 + 1) * financedAmount * Math.pow((principleBasedMonthlyRatePerMillion / 1000000 + 1) * (monthlyRate + 1), adjustedTerm))/((principleBasedMonthlyRatePerMillion / 1000000 + 1)/(principleBasedMonthlyRatePerMillion / 1000000 * monthlyRate + principleBasedMonthlyRatePerMillion / 1000000 + monthlyRate) - ((principleBasedMonthlyRatePerMillion / 1000000 + 1) * Math.pow((principleBasedMonthlyRatePerMillion / 1000000 + 1) * (monthlyRate + 1), adjustedTerm))/(principleBasedMonthlyRatePerMillion / 1000000 * monthlyRate + principleBasedMonthlyRatePerMillion / 1000000 + monthlyRate));

    // If the is an origination fee on the balloon loan, then the APR will differ from the base interest rate.
    // We compute the APR below in order to display it in disclosure verbiage on the site, as required by regulation.
    let apr = rate.interestRate;
    if (balloonLoanOriginationFee > 0) {
      // Calculate the APR, which is the interest rate which results in the same monthly payment amount as determined above, but without the origination fee added to the financedAmount
      const financedAmountWithoutFees = financedAmount - balloonLoanOriginationFee;

      // Increment the test APR until we find the number that reproduces the original payment amount
      let testPaymentAmount = 0;
      let testMonthlyApr = monthlyRate;
      while (
        testPaymentAmount < paymentAmount &&
        testMonthlyApr > 0 // <-- to ensure no division by zero can happen below
      ) {
        testMonthlyApr += 0.000001;
        testPaymentAmount = (financedAmountWithoutFees * testMonthlyApr - adjustedEndingBalance * testMonthlyApr) / (Math.pow(1 + testMonthlyApr, adjustedTerm) - 1) + financedAmountWithoutFees * testMonthlyApr;
      }
      apr = Math.round(testMonthlyApr * 10000 * 12) / 100;
    }

    return {isFallbackRate: rate.isFallbackRate, amount: Math.ceil(paymentAmount), rate: rate, apr: apr};
  }

}