import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FavoriteVehiclesService } from '@services/favorite-vehicles.service';
import { ConfigService } from '@services/config.service';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-favorite-vehicles-list',
  templateUrl: './favorite-vehicles-list.component.html',
  styleUrls: ['./favorite-vehicles-list.component.sass']
})
export class FavoriteVehiclesListComponent implements OnInit {

  private readonly MAX_DISPLAY = 5

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe
  showAll: boolean = false;

  constructor(
    public favoriteVehiclesService: FavoriteVehiclesService,
    private configService: ConfigService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  compare() {
    const classifiedAdIDs = this.favoriteVehiclesService.listings.map(listing => listing.classifiedAdID).toString();
    this.router.navigate(['/compareVehicles', classifiedAdIDs]);
  }

  getDisplayListings() {
    if (this.showAll) {
      return this.favoriteVehiclesService.listings;
    } else {
      return this.favoriteVehiclesService.listings.slice(0, this.MAX_DISPLAY);
    }
  }

  someAreHidden() {
    return this.favoriteVehiclesService.savedListingCount > this.MAX_DISPLAY;
  }

  viewListing(event: MouseEvent, classifiedAdID: string) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    const routerCommands = ['/listing', classifiedAdID];
    if (openInNewWindow) {
      window.open(this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + this.router.createUrlTree(routerCommands).toString());
    } else {
      this.router.navigate(routerCommands);
    }
  }

}
