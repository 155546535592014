import { Component, OnInit } from '@angular/core';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-iframe-helper',
  templateUrl: './iframe-helper.component.html',
  styleUrls: ['./iframe-helper.component.sass']
})
export class IframeHelperComponent implements OnInit {

  // Used in implementing 'fix to top' functionality
  private lastScrollYPosition = 0;
  private lastScrollYPositionChangeTimestamp = 0;

  constructor() { }

  ngOnInit() {
    // Create an iframe that will overlay the entire body.
    // We can then watch for resize events on the iframe to detect body height changes.
    // The iframe needs to be a direct child of the body, so we cannot put the iframe code in the component html/css files.
    const iframe = document.createElement('iframe');
    iframe.tabIndex = -1;
    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.width = '0';
    iframe.style.height = '100%';
    iframe.style.border = '0';
    iframe.addEventListener('load', () => {
      // Trigger an initial resize notice so that the initial height gets set
      this.bodyResized();

      // Watch for further resizes
      if (iframe.contentWindow) {
        iframe.contentWindow.addEventListener('resize', this.bodyResized);
      } else {
        throw "Overlay iframe contentWindow not initialized!"; // This should never happen
      }
    });
    document.body.style.position = 'relative';
    document.body.appendChild(iframe);



    // Watch for scroll changes and implement 'fix to top' functionality
    window.addEventListener('message', event => {
      if (event.origin === environment.apiBaseUrl && event.data['eventType'] === 'scrollYChange') {
        const newScrollYPosition = event.data['scrollYPosition'];
        if (newScrollYPosition !== this.lastScrollYPosition) {
          // Record the new scroll position
          this.lastScrollYPosition = newScrollYPosition;
          this.lastScrollYPositionChangeTimestamp = Date.now();

          // Since a recent scroll change happened, adjust the position of 'fix to top' elements
          // The interval & timestamp check prevents us from updating the 'top' when the user is still actively scrolling the page (which looks odd)
          const topUpdateInterval = setInterval(() => {
            if (this.lastScrollYPositionChangeTimestamp <= Date.now() - 100) {
              // Adjust the 'top' value for any elements which should be fixed to top
              document.querySelectorAll('.fix-to-top').forEach(elm => {
                const htmlElm = elm as HTMLElement;
                htmlElm.style.top = this.lastScrollYPosition + 'px';

                // If this element should only be shown below a specific y-position, hide/show it as needed
                const showBelow = elm.getAttribute('data-show-below');
                if (showBelow) {
                  if (this.lastScrollYPosition > parseInt(showBelow)) {
                    htmlElm.style.opacity = '1';
                  } else {
                    htmlElm.style.opacity = '0';
                  }
                }
              });
              clearInterval(topUpdateInterval);
            }
          }, 100);
        }
      }
    });



    // Watch for the creation of any modals
    // When any are found, they need to have the modal-init class taken off of them and
    // their 'top' set. This will allow them to be displayed and positioned within view.
    window.addEventListener('modalCreated', event => {
      document.querySelectorAll('.modal-init').forEach(modal => {
        const htmlElm = modal as HTMLElement;
        htmlElm.style.top = this.lastScrollYPosition + 'px';
        modal.classList.remove('modal-init');

        // We need to manually focus on the close button after the modal is initialized, since it will autofocus on our "workaround" invisible input
        // Without this, keyboard focus won't be in the modal by default
        const closeButton = modal.querySelector('.close') as HTMLElement;
        if (closeButton) {
          closeButton.focus({ preventScroll: true });
        }
      });
    });
  }

  bodyResized() {
    const documentElement = document.documentElement;
    if (documentElement && documentElement.offsetHeight) {
      window.parent.postMessage({eventType: 'setHeight', height: documentElement.offsetHeight}, '*');
    }
  }

}
