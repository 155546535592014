import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.sass']
})
export class ImageSliderComponent implements OnInit {

  @Input() imageUrl!: string;
  @Input() small!: boolean;
  @Input() vcsEnabled: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
