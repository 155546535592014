import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferrerMessagingService {

  private _isMessagingChannelOpen: boolean = false;

  constructor() {
    window.addEventListener('message', event => {
      if (event.origin === environment.apiBaseUrl && event.data['eventType'] === 'referrerMessagingChannelStatus') {
        if (event.data['channelOpen']) {
          this._isMessagingChannelOpen = true;
        } else {
          this._isMessagingChannelOpen = false;
        }
      }
    });

    // Annouce that we are now ready to receive these referrerMessagingChannelStatus messages
    window.parent.postMessage({eventType: 'enableReferrerMessagingChannelStatus'}, environment.apiBaseUrl);
  }

  get isMessagingChannelOpen(): boolean {
    return this._isMessagingChannelOpen;
  }

  sendVehicleSelection(classifiedAdID: string/*, loanCalcParameters: LoanCalcParameters*/) {
    // TODO: POST the data to our server

    // Send notice through the messaging channel that the vehicle has been selected
    window.parent.postMessage({eventType: 'vehicleSelection', classifiedAdID: classifiedAdID}, environment.apiBaseUrl);
  }

}
