<form [formGroup]="leadForm" (ngSubmit)="doSubmitLead()" *ngIf="!isLeadSubmitted" class="validated-form">
  <div *ngIf="shouldShowApplyButton" class="p-3 bg-light">
    <h3>Choose This Vehicle</h3>
    <p>Is this the one you want? Put this vehicle on your loan application.</p>
    <button type="button" class="btn btn-primary" (click)="chooseThisVehicle()">Choose This Vehicle</button>
  </div>
  <br *ngIf="shouldShowApplyButton">
  <fieldset class="p-3 bg-light">
    <h3 *ngIf="isVehicleHistoryRequest">Enter Your Contact Information</h3>
    <div class="l2-rating-box-cont bg-primary">
      <div class="l2-rating-box-title">
        <h3 *ngIf="!isVehicleHistoryRequest" class="l2-sidebar-contact">Contact Seller</h3>
      </div>
      <app-price-rating-vdp [rating]="listing.priceRating" class="l2-rating-arrow"></app-price-rating-vdp>
    </div>

    <div class="form-row l2-form-names">
      <div class="col-lg form-group l2-form-group">
        <label for="firstNameInput">First Name</label>
        <input id="firstNameInput" formControlName="firstName" class="form-control l2-pp-fields" required>
      </div>
      <div class="col-lg form-group">
        <label for="lastNameInput">Last Name</label>
        <input id="lastNameInput" formControlName="lastName" class="form-control l2-pp-fields" required>
      </div>
    </div>
    <div class="form-row">
      <div class="col-lg form-group">
        <label for="emailInput">Email Address</label>
        <input id="emailInput" formControlName="emailAddress" class="form-control l2-pp-fields" type="email" required>
      </div>
      <div *ngIf="listing.moreDetail?.leadRequirements?.zipCode == 'IsNotApplicable'" class="col-lg form-group">
        <div [ngSwitch]=listing.moreDetail?.leadRequirements?.phoneNumber>
          <div *ngSwitchCase="'IsOptional'">
            <label for="phoneInput">Phone (optional)</label>
            <input id="phoneInput" formControlName="phoneNumber" class="form-control l2-pp-fields" (keyup)="formatPhoneInLeadForm()">
          </div>
          <div *ngSwitchCase="'IsRequired'">
            <label for="phoneInput">Phone</label>
            <input id="phoneInput" formControlName="phoneNumber" class="form-control l2-pp-fields" (keyup)="formatPhoneInLeadForm()" required>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="listing.moreDetail?.leadRequirements?.zipCode != 'IsNotApplicable'" class="form-row">
      <div class="col-lg form-group">
        <div [ngSwitch]=listing.moreDetail?.leadRequirements?.phoneNumber>
          <div *ngSwitchCase="'IsOptional'">
            <label for="phoneInput">Phone (optional)</label>
            <input id="phoneInput" formControlName="phoneNumber" class="form-control l2-pp-fields" (keyup)="formatPhoneInLeadForm()">
          </div>
          <div *ngSwitchCase="'IsRequired'">
            <label for="phoneInput">Phone</label>
            <input id="phoneInput" formControlName="phoneNumber" class="form-control l2-pp-fields" (keyup)="formatPhoneInLeadForm()" required>
          </div>
        </div>
      </div>
      <div class="col-lg form-group">
        <div [ngSwitch]=listing.moreDetail?.leadRequirements?.zipCode>
          <div *ngSwitchCase="'IsOptional'">
            <label for="zipInput">Zip Code (Optional)</label>
            <input id="zipInput" formControlName="zipCode" class="form-control l2-pp-fields">
          </div>
          <div *ngSwitchCase="'IsRequired'">
            <label for="zipInput">Zip Code</label>
            <input id="zipInput" formControlName="zipCode" class="form-control l2-pp-fields" required>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isVehicleHistoryRequest">
      <button type="submit" class="btn btn-primary mt-1" [disabled]="leadForm.invalid">Submit Request</button>
    </ng-container>
    <ng-container *ngIf="!isVehicleHistoryRequest">
      <div class="form-row">
        <div class="col form-group mb-3">
          <label for="commentInput">Comment (optional)</label>
          <select class="custom-select l2-pp-fields" (change)="prefillComment($event.target)" #prefillCommentSelect>
            <option data-submit-label="Request Total Price" data-prefill="I'd like to know my out-the-door price (the bottom line total including taxes, title, and fees) for the %VEHICLE% you have listed for %PRICE%.">Get my out-the-door price</option>
            <option data-submit-label="Check Availability" data-prefill="I'd like to know if the %VEHICLE% you have listed for %PRICE% is still available.">Check availability</option>
            <option data-submit-label="Request Quote" data-prefill="I'd like to know your best price for the %VEHICLE% you have listed for %PRICE%.">Get a price quote</option>
            <option data-submit-label="Request More Photos" data-prefill="I'd like to request additional photos of the %VEHICLE% you have listed for %PRICE%.">Request more photos</option>
            <option data-submit-label="Request Appointment" data-prefill="I'd like to schedule a test drive with the %VEHICLE% you have listed for %PRICE%.">Schedule a test drive</option>
            <option data-submit-label="Submit Question" data-prefill="I have a question about the %VEHICLE% you have listed for %PRICE%.">Ask a question</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col form-group">
          <textarea id="commentInput" formControlName="comment" class="form-control l2-pp-fields" rows="5"></textarea>
        </div>
      </div>
      <div class="form-group" *ngIf="afg.mayBeEligible(listing, true)">
        <div class="d-flex justify-content-between align-items-center">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="checkBalloonLoan" class="custom-control-input" id="balloonLoanCheck">
            <label class="custom-control-label" for="balloonLoanCheck">Send me information about lower monthly payments with <a [routerLink]="['/loans', listing.classifiedAdID]">{{afg.programNameIndefiniteArticle}} {{afg.programName}}</a> Loan</label>
          </div>
          <span class="questionMark badge badge-pill badge-primary" placement="top" ngbTooltip="The credit union will send you more information">?</span>
        </div>
      </div>
      <p class="alert alert-warning mt-1" *ngIf="leadType !== 0">This will submit a test lead invisible to <ng-container *ngIf="leadType === 2">the credit union and </ng-container>the vehicle seller.</p>
      <div *ngIf="isTrueCarDealer()" class="disclaimer mt-3 py-2 px-3 small">
        <div class="text-justify">
          By providing your information and clicking the button below, you provide your electronic signature, share your contact information with and agree to be contacted by TrueCar, Inc. and/or its certified dealers about this vehicle or other vehicles for lease or purchase through the use of automated technology (e.g., automatic telephone dialing system, text, artificial or pre-recorded voice). Consent for automated marketing calls/texts is not a condition of purchasing any vehicle, goods or services. You also agree to <a href="https://autolink.io/partners/terms-and-privacy" target="_blank">Auto Link's terms of use</a>. For California residents: To the extent this request is considered a sale under the California Consumer Privacy Act of 2018, you expressly authorize the sale of your personal information.
        </div>
      </div>
      <button type="submit" class="mt-2 btn btn-lg btn-primary w-100" [disabled]="leadForm.invalid">{{submitBtnLabel}}</button>
      <div *ngIf="isTrueCarDealer()">
        <img src="/assets/img/Truecar_logofile.png" alt="TrueCar" class="listing-provider-logo pt-3 pb-1">
        <p class="small"><strong>This dealership is a TrueCar Certified Dealer.</strong> Certified Dealers are contractually obligated by TrueCar to meet certain customer service requirements and complete the TrueCar Dealer Certification Program.</p>
      </div>
      <div *ngIf="!isTrueCarDealer()" class="disclaimer mt-3 py-2 px-3 small text-muted">
        <div class="text-justify">
          By submitting this form, you authorize the credit union, the vehicle seller, and/or our other partners to contact you by texts/emails/calls which may include marketing and be by autodialer. Consent is not required to purchase goods/services.
        </div>
      </div>
    </ng-container>
  </fieldset>
  <div *ngIf="!isVehicleHistoryRequest && !shouldShowApplyButton && (configService.loanAppUrl$ | async)?.length" class="mt-4 bg-light p-3 l2-pp-getapproved-box">
    <h3 class="bg-primary l2-pp-getapproved">Get Approved for a Loan</h3>
    <p class="l2-pp-getapproved-p">Are you pre-approved for your loan? If not, get pre-approved now and find out how much you're qualified to borrow. <ng-container *ngIf="configService.lowestRate >= 0">Rates start as low as {{configService.lowestRate}}%.</ng-container></p>
    <a target="_blank" [href]="configService.loanAppUrl$ | async" class="btn btn-primary l2-pp-getapproved-a" (click)="userSessionService.recordLoanAppAccess()">Get Pre-Approved</a>
  </div>
</form>

<div *ngIf="isLeadSubmitted" class="leadSubmitted">
  <p><strong>Your request has been submitted. You should hear back soon.</strong></p>
  <ng-container *ngIf="!isVehicleHistoryRequest">
    <p><strong>Dealer Name:</strong> {{listing.seller.name}}</p>
    <p><strong>Dealer Address:</strong> {{listing.seller.address}}, {{listing.location.city}}, {{listing.location.state}}</p>
    <p *ngIf="listing.seller.phone"><strong>Dealer Phone Number:</strong> {{formatPhone(listing.seller.phone)}}</p>

    <p><strong>Next:</strong> If you have not done so already, please fill out an online loan application. Having this completed will speed up the process of purchasing your vehicle. Filling out the loan application does not obligate you to purchase a vehicle, but it will inform you what your interest rate will be and how much you are qualified to borrow.</p>
    <div class="d-flex flex-column">
      <button *ngIf="afg.mayBeEligible(listing)" type="button" class="btn btn-success btn-lg mb-2" [routerLink]="['/loans', listing.classifiedAdID]">Lower My Payment</button>
      <a *ngIf="(configService.loanAppUrl$ | async)?.length" target="_blank" [href]="configService.loanAppUrl$ | async" class="btn btn-primary btn-lg" (click)="userSessionService.recordLoanAppAccess()">Get Pre-Approved</a>
    </div>
  </ng-container>
</div>