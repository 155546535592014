<div *ngIf="isCalculatable()"><span class="text-primary amount" (click)="open(paymentDetails)">{{payment$ | async | currency:'USD':digitsInfo:'1.0-0'}} / month *</span></div>
<div *ngIf="!isCalculatable()"><span class="text-muted">n/a - contact us</span></div>

<ng-template #paymentDetails let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Estimate Your Monthly Payment</h4>
    <!-- This input is used to trigger the angular bootstrap focus on an invisible element, otherwise, the autofocus on the modal's X will scroll the viewport to the top of the page -->
    <input type="text" style="display: none" />
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-sm table-borderless mb-2">
      <thead class="thead-dark">
        <tr>
          <th>Description</th>
          <th class="text-right text-nowrap"><span *ngIf="showVehicle || showProducts || showCosts || showAdvancePayments">Amount</span></th>
          <th class="text-right text-nowrap" *ngIf="conventionalLoan">Monthly</th>
        </tr>
      </thead>
      <tbody>
        <tr (click)="toggleShowVehicle()" class="clickable">
          <td colspan="2" class="font-weight-bold"><span *ngIf="!showVehicle" class="line-item-arrow">►</span><span *ngIf="showVehicle" class="line-item-arrow">▼</span> Vehicle price</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{getMonthlyComponent(listing.price) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr *ngIf="showVehicle" class="small text-muted">
          <td>{{listing.year}} {{listing.make}} {{listing.model}} {{listing.trim}}</td>
          <td class="text-right text-nowrap">{{listing.price | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{getMonthlyComponent(listing.price) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr *ngIf="configService.productInformation" (click)="toggleShowProducts()" class="clickable" [ngClass]="{'strikethrough': noProductsEnabled() && listing.products.length > 0}">
          <td colspan="2" class="font-weight-bold">
            <span *ngIf="!showProducts" class="line-item-arrow">►</span><span *ngIf="showProducts" class="line-item-arrow">▼</span> Investment protection
            <ng-container *ngIf="listing.products.length > 0">
              <img class="product-icon" src="/assets/img/shield-green.svg" alt="" *ngIf="allNonblockedProductsEnabled()">
              <img class="product-icon" src="/assets/img/shield-yellow.svg" alt="" *ngIf="!allNonblockedProductsEnabled() && !noProductsEnabled()">
              <img class="product-icon" src="/assets/img/shield-red.svg" alt="" *ngIf="noProductsEnabled()">
            </ng-container>
          </td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">
            <ng-container *ngIf="listing.products.length > 0">
              <span class="text-muted">plus</span> {{getAllProductsMonthlyComponent() | currency:'USD':digitsInfo:'1.0-0'}} / mo.
            </ng-container>
            <ng-container *ngIf="listing.products.length === 0">
              <span class="text-muted">not applicable</span>
            </ng-container>
          </td>
        </tr>
        <ng-container *ngIf="showProducts">
          <tr *ngFor="let product of listing.products" class="small text-muted">
            <ng-container [ngSwitch]="product.productType">
              <td *ngSwitchCase="'GAP'">
                <strong>{{configService.productInformation?.gap?.name || 'GAP'}}</strong>
                <p class="mb-0" *ngIf="configService.productInformation?.gap?.educationVerbiage">{{configService.productInformation?.gap?.educationVerbiage}}</p>
                <p class="mb-0" *ngIf="configService.productInformation?.gap?.link"><a class="text-info" href="#" (click)="openProductInfoWindow(configService.productInformation?.gap?.link)">Learn more</a></p>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch_gap" [checked]="isProductEnabled(product)" (change)="toggleProduct(product)">
                  <label class="custom-control-label" for="customSwitch_gap">Include this protection</label>
                </div>
              </td>
              <td *ngSwitchCase="'DP1'">
                <strong>{{configService.productInformation?.dp?.name || 'Credit life &amp; disability (single)'}}</strong>
                <p class="mb-0" *ngIf="configService.productInformation?.dp?.educationVerbiage">{{configService.productInformation?.dp?.educationVerbiage}}</p>
                <p class="mb-0" *ngIf="configService.productInformation?.dp?.link"><a class="text-info" href="#" (click)="openProductInfoWindow(configService.productInformation?.dp?.link)">Learn more</a></p>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch_dp1" [checked]="isProductEnabled(product)" (change)="toggleProduct(product)">
                  <label class="custom-control-label" for="customSwitch_dp1">Include this protection</label>
                </div>
              </td>
              <td *ngSwitchCase="'DP2'">
                <strong>{{configService.productInformation?.dp2?.name || 'Credit life &amp; disability (joint)'}}</strong>
                <p class="mb-0" *ngIf="configService.productInformation?.dp2?.educationVerbiage">{{configService.productInformation?.dp2?.educationVerbiage}}</p>
                <p class="mb-0" *ngIf="configService.productInformation?.dp2?.link"><a class="text-info" href="#" (click)="openProductInfoWindow(configService.productInformation?.dp2?.link)">Learn more</a></p>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch_dp2" [checked]="isProductEnabled(product)" (change)="toggleProduct(product)">
                  <label class="custom-control-label" for="customSwitch_dp2">Include this protection</label>
                </div>
              </td>
              <td *ngSwitchCase="'VSC'">
                <strong>{{configService.productInformation?.vsc?.name || 'Vehicle service contract'}}</strong>
                <p class="mb-0" *ngIf="getVSCProductDescription()">{{getVSCProductDescription()}}</p>
                <p class="mb-0" *ngIf="configService.productInformation?.vsc?.educationVerbiage">{{configService.productInformation?.vsc?.educationVerbiage}}</p>
                <p class="mb-0" *ngIf="configService.productInformation?.vsc?.link"><a class="text-info" href="#" (click)="openProductInfoWindow(configService.productInformation?.vsc?.link)">Learn more</a></p>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch_vsc" [checked]="isProductEnabled(product)" (change)="toggleProduct(product)">
                  <label class="custom-control-label" for="customSwitch_vsc">Include this protection</label>
                </div>
              </td>
            </ng-container>
            <td [ngClass]="{'strikethrough': !isProductEnabled(product)}" class="text-right text-nowrap">
              <ng-container *ngIf="product.priceData.kind === 'FixedProductPrice'">
                <span>{{product.priceData.amount | currency:'USD':digitsInfo:'1.0-0'}}</span>
              </ng-container>
              <ng-container *ngIf="product.priceData.kind === 'PrincipalBasedMonthlyRateProductPrice'">
                <span><!-- No fixed amount for this type of product --></span>
              </ng-container>
            </td>
            <td [ngClass]="{'strikethrough': !isProductEnabled(product)}" class="text-right text-nowrap" *ngIf="conventionalLoan">
              <ng-container *ngIf="product.priceData.kind === 'FixedProductPrice'">
                <span>{{getMonthlyComponent(product.priceData.amount) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</span>
              </ng-container>
              <ng-container *ngIf="product.priceData.kind === 'PrincipalBasedMonthlyRateProductPrice'">
                <span>{{getMonthlyComponentForPrincipalBasedProduct(product.priceData.amountPerMillion) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</span>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="listing.products.length === 0" class="small text-muted">
            <td [attr.colspan]="(conventionalLoan ? 3 : 2)">No investment production products can be quoted online for this vehicle. Check with your loan officer to see what options are available offline.</td>
          </tr>
        </ng-container>
        <tr (click)="toggleShowCosts()" class="clickable">
          <td colspan="2" class="font-weight-bold"><span *ngIf="!showCosts" class="line-item-arrow">►</span><span *ngIf="showCosts" class="line-item-arrow">▼</span> Other costs</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan"><span class="text-muted">plus</span> {{getOtherCostsMonthlyComponent() | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="showCosts">
          <td>Sales tax ({{(paymentService.params$ | async)?.salesTaxRate}}%)</td>
          <td class="text-right text-nowrap">{{listing.price * (((paymentService.params$ | async)?.salesTaxRate ?? 0)/100) | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{getMonthlyComponent(listing.price * (((paymentService.params$ | async)?.salesTaxRate ?? 0)/100)) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="showCosts && hasHighPricedVehicleSurcharge">
          <td>High priced vehicle surcharge</td>
          <td class="text-right text-nowrap">{{highPricedVehicleSurcharge | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{getMonthlyComponent(highPricedVehicleSurcharge) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="showCosts && !conventionalLoan && configService.balloonLoanFee > 0">
          <td>{{afg.programName}} loan booking fee</td>
          <td class="text-right text-nowrap">{{configService.balloonLoanFee | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{getMonthlyComponent(configService.balloonLoanFee) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="showCosts">
          <td>Estimated dealer and state fees<sup>&#9663;</sup></td>
          <td class="text-right text-nowrap">{{(paymentService.params$ | async)?.transactionFees | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{getMonthlyComponent((paymentService.params$ | async)?.transactionFees ?? 0) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr (click)="toggleShowAdvancePayments()" class="clickable">
          <td colspan="2" class="font-weight-bold"><span *ngIf="!showAdvancePayments" class="line-item-arrow">►</span><span *ngIf="showAdvancePayments" class="line-item-arrow">▼</span> Up-front payments</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan"><span class="text-muted">less</span> {{getMonthlyComponent((paymentService.params$ | async)?.tradeInValue ?? 0) + getMonthlyComponent(((paymentService.params$ | async)?.tradeInValue ?? 0) * (((paymentService.params$ | async)?.salesTaxRate ?? 0)/100)) + getMonthlyComponent((paymentService.params$ | async)?.otherDownPayment ?? 0) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="showAdvancePayments">
          <td>Trade-in</td>
          <td class="text-right text-nowrap">-{{(paymentService.params$ | async)?.tradeInValue | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">-{{getMonthlyComponent((paymentService.params$ | async)?.tradeInValue ?? 0) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="(paymentService.params$ | async)?.tradeInSalesTaxCredit && ((paymentService.params$ | async)?.tradeInValue ?? 0) > 0 && showAdvancePayments">
          <td>Trade-in sales tax credit</td>
          <td class="text-right text-nowrap">-{{((paymentService.params$ | async)?.tradeInValue ?? 0) * (((paymentService.params$ | async)?.salesTaxRate ?? 0)/100) | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">-{{getMonthlyComponent(((paymentService.params$ | async)?.tradeInValue ?? 0) * (((paymentService.params$ | async)?.salesTaxRate ?? 0)/100)) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="small text-muted" *ngIf="showAdvancePayments">
          <td>Other down payment</td>
          <td class="text-right text-nowrap">-{{(paymentService.params$ | async)?.otherDownPayment | currency:'USD':digitsInfo:'1.0-0'}}</td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">-{{getMonthlyComponent((paymentService.params$ | async)?.otherDownPayment ?? 0) | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
        <tr class="table-warning font-weight-bold">
          <td colspan="2"><div class="d-flex justify-content-between"><span>Total financed: {{calcTotal() | currency:'USD':digitsInfo:'1.0-0'}}</span><span *ngIf="!conventionalLoan">{{payment$ | async | currency:'USD':digitsInfo:'1.0-0'}} / mo.</span></div></td>
          <td class="text-right text-nowrap" *ngIf="conventionalLoan">{{payment$ | async | currency:'USD':digitsInfo:'1.0-0'}} / mo.</td>
        </tr>
      </tbody>
    </table>
    <div class="p-3 form-container">
      <form [formGroup]="paymentParameterForm">
        <div class="row">
          <div class="form-group mb-1 col-sm-6">
            <label class="small mb-0" for="termInput">term</label>
            <select id="termInput" formControlName="term" class="custom-select custom-select-sm">
              <option *ngFor="let termLength of configService.availableLoanTerms" [value]="termLength">{{termLength/12}} year<ng-container *ngIf="termLength/12 > 1">s</ng-container></option>
            </select>
          </div>
          <div *ngIf="showCreditScoreSelector$() | async" class="form-group mb-1 col-sm-6 d-flex flex-column justify-content-end">
            <ng-container *ngIf="paymentService.params$.value.rateService.kind === 'creditScoreTable'">
              <ng-container *ngIf="paymentService.params$.value.rateService['creditTierScores'].length === 1">
                <div class="small border p-1 bg-light">All qualified borrowers get the same {{getRate()}}% auto loan rate.</div>
              </ng-container>
              <ng-container *ngIf="paymentService.params$.value.rateService['creditTierScores'].length > 1">
                <label class="small mb-0" for="creditTierIdInput">your credit score<span class="ml-3">({{getRate()}}% rate)</span></label>
                <select id="creditTierIdInput" formControlName="creditTierScore" class="custom-select custom-select-sm">
                  <option *ngFor="let creditTierScore of paymentService.params$.value.rateService['creditTierScores']" [value]="creditTierScore">{{creditTierScore}}+</option>
                </select>
              </ng-container>
            </ng-container>
          </div>
          <div *ngIf="showManualRateInput$() | async" class="form-group mb-1 col-sm-6">
            <label class="small mb-0" for="interestRateInput">interest rate<sup>&#9662;</sup></label>
            <div class="input-group input-group-sm">
              <input formControlName="rate" id="interestRateInput" type="number" class="form-control loan-input">
              <div class="input-group-append"><span class="input-group-text">%</span></div>
            </div>
          </div>
          <div class="col-12 small mb-1" *ngIf="getTerm() !== paymentParameterForm.value.term">
            Due to the age of this vehicle, it cannot be financed on a term length of {{paymentParameterForm.value.term/12}} year<ng-container *ngIf="paymentParameterForm.value.term/12 > 1">s</ng-container>. A term of {{getTerm()/12}} year<ng-container *ngIf="getTerm()/12 > 1">s</ng-container> will be used instead.
          </div>
          <div class="form-group mb-1 col-sm-6">
            <label class="small mb-0" for="downPaymentInput">down payment</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend"><span class="input-group-text">$</span></div>
              <input formControlName="downPayment" id="downPaymentInput" type="number" class="form-control loan-input">
            </div>
          </div>
          <div class="form-group mb-1 col-sm-6">
            <label class="small mb-0" for="tradeInInput">trade in<span (click)="goToTradeIn()" class="loan-edit-link trade-in-link ml-3">check trade-in value</span></label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend"><span class="input-group-text">$</span></div>
              <input formControlName="tradeIn" id="tradeInInput" type="number" class="form-control loan-input">
            </div>
          </div>
          <div class="form-group mb-1 col-sm-6">
            <label class="small mb-0" for="salesTaxRateInput">sales tax rate</label>
            <div class="input-group input-group-sm">
              <input formControlName="salesTaxRate" id="salesTaxRateInput" type="number" class="form-control loan-input">
              <div class="input-group-append"><span class="input-group-text">%</span></div>
            </div>
          </div>
          <div class="form-group mb-1 col-sm-6">
            <label class="small mb-0" for="transactionFeesInput">estimated dealer and state fees</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend"><span class="input-group-text">$</span></div>
              <input formControlName="transactionFees" id="transactionFeesInput" type="number" class="form-control loan-input">
            </div>
          </div>
          <div class="form-group mb-1 col-12">
            <div class="form-check mb-1 col-12 small">
              <input class="form-check-input" type="checkbox" id="tradeInSalesTaxCreditInput" formControlName="tradeInSalesTaxCredit">
              <label class="form-check-label" for="tradeInSalesTaxCreditInput">
                sales tax trade-in credit
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <p class="small mt-2 mb-0 text-muted" *ngIf="showManualRateInput$() | async"><sup>&#9662;</sup> The interest rate for this vehicle for a term length of {{(paymentService.params$ | async)?.preferredTermMonths}} months cannot be determined online. Please give us a call <ng-container *ngIf="configService.loanDepartmentPhone != ''">at {{configService.loanDepartmentPhone}}</ng-container> if you wish to know exactly what interest rate would apply to this vehicle at this loan term length. You can use the <em>interest rate</em> input field above to explore how different rates affect your money payment.</p>
    <p class="small mt-2 mb-0 text-muted" *ngIf="showCosts"><sup>&#9663;</sup> Estimated fees include dealer's documentation fees, state title/tag/registration fees, etc. The default amount shown is an estimate based on typical fees. Contact the dealer to find out what the actual fees will be for this vehicle.</p>
    <p class="small px-2 py-1 bg-warning mt-2 mb-0">
      <sup>*</sup>
      <ng-container *ngIf="!configService.customPaymentDisclaimer">
        We try to provide a reasonable payment estimate here, but your actual payment may vary due to factors that we cannot estimate online. <span *ngIf="listing.products.length > 0">Investment protection product pricing may vary based on mileage, coverage option chosen, and vehicle. The price presented is an estimate.</span> Work with your loan officer to determine your exact monthly payment.
      </ng-container>
      <ng-container *ngIf="configService.customPaymentDisclaimer">
        <span [innerHtml]="breakLines(configService.customPaymentDisclaimer)"></span>
      </ng-container>
      <ng-container *ngIf="!conventionalLoan">The monthly payment on a {{afg.programName}} loan may be greater if you expect to drive more than {{afg.DEFAULT_MILEAGE}} miles per year.</ng-container>
    </p>
  </div>
</ng-template>
