import { AbstractControl } from "@angular/forms";

/**
 * Regex being used to validate phone numbers.
 *
 * NOTE: Does not match country codes
 *
 * Same as in app/models/VirtualCarSaleService.scala
 *
 * Source: www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
 */
const phoneNumberRegex = /^(\+?[0-9]{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

/**
 * Phone number validator for Reactive Forms
 *
 * @param control Form control object
 * @returns Null if the phone number is valid, otherwise an object containing the invalid value
 */
export default function phoneNumberValidator(control: AbstractControl) {
  if (control.value === "") return null;

  const isValid = phoneNumberRegex.test(control.value);
  if (isValid) return null;
  else return { invalidPhoneNumber: control.value };
}
