import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { MonthlyPaymentComponent } from "./monthly-payment/monthly-payment.component";
import { IframeHelperComponent } from "./iframe-helper/iframe-helper.component";
import { ImageSliderComponent } from "./image-slider/image-slider.component";
import { LeadFormComponent } from "./lead-form/lead-form.component";
import { AccountBtnsComponent } from "./account-btns/account-btns.component";
import { AccountBtnsComponentL2 } from "./account-btns/account-btns-l2.component";
import { PriceRatingComponent } from "./price-rating/price-rating.component";
import { PriceRatingVDPComponent } from "./price-rating-vdp/price-rating-vdp.component";
import { PriceRatingSearchComponent } from "./price-rating-search/price-rating-search.component";
import { FavoriteVehiclesListComponent } from "./favorite-vehicles-list/favorite-vehicles-list.component";
import { SaveVehicleButtonComponent } from "./save-vehicle-button/save-vehicle-button.component";
import { FooterComponent } from "./footer/footer.component";
import { VehicleTopBarComponent } from "./vehicle-top-bar/vehicle-top-bar.component";
import { VehicleTopBarSearchComponent } from "./vehicle-top-bar-search/vehicle-top-bar-search.component";
import { DealerTagsBadgeComponent } from "./dealer-tags-badge/dealer-tags-badge.component";
import { NoFinancingNoticeComponent } from './no-financing-notice/no-financing-notice.component';
import { VSCodeComponent } from './vs-code/vs-code.component';
import { AfgBadgeComponent } from "./afg-badge/afg-badge.component";
import { CustomFilterBadgesComponent } from "./custom-filter-badges/custom-filter-badges.component";

@NgModule({
  declarations: [
    FooterComponent,
    FavoriteVehiclesListComponent,
    IframeHelperComponent,
    ImageSliderComponent,
    LeadFormComponent,
    AccountBtnsComponent,
    AccountBtnsComponentL2,
    MonthlyPaymentComponent,
    PriceRatingComponent,
    PriceRatingVDPComponent,
    PriceRatingSearchComponent,
    SaveVehicleButtonComponent,
    VehicleTopBarComponent,
    VehicleTopBarSearchComponent,
    DealerTagsBadgeComponent,
    NoFinancingNoticeComponent,
    VSCodeComponent,
    AfgBadgeComponent,
    CustomFilterBadgesComponent,
  ],
  exports: [
    FooterComponent,
    FavoriteVehiclesListComponent,
    IframeHelperComponent,
    ImageSliderComponent,
    LeadFormComponent,
    AccountBtnsComponent,
    AccountBtnsComponentL2,
    MonthlyPaymentComponent,
    PriceRatingComponent,
    PriceRatingVDPComponent,
    PriceRatingSearchComponent,
    SaveVehicleButtonComponent,
    VehicleTopBarComponent,
    VehicleTopBarSearchComponent,
    DealerTagsBadgeComponent,
    NoFinancingNoticeComponent,
    VSCodeComponent,
    AfgBadgeComponent,
    CustomFilterBadgesComponent,
    NgbModule,
  ],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, NgbModule],
})
export class VehicleSearchSharedModule {}
