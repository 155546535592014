import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-price-rating-search',
  templateUrl: './price-rating-search.component.html',
  styleUrls: ['./price-rating-search.component.sass']
})
export class PriceRatingSearchComponent implements OnInit {

  @Input() rating!: string;

  ngOnInit() {
  }

}
