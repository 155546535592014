import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

import { ConfigService } from '@services/config.service';
import { UserData } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';

import { environment } from '@environments/environment';

export interface LoginData {
  emailAddress: string;
  password: string;
}

// Should match SavedSearch class in Scala
export interface SavedSearch {
  savedSearchID: number;
  searchData: Record<string, string | string[]>;
}

// Should match SessionUserResponse class in Scala
export interface SessionUserResponse {
  isLoggedIn: boolean;
  userData: UserData;
  favoriteVehicles: VehicleListing[];
  savedSearches: SavedSearch[];
}

// Should match CarFaxResponse class in Scala
export interface CarFaxResponse {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class EndUsersService {

  favoriteVehicles$: ReplaySubject<VehicleListing[]> = new ReplaySubject<VehicleListing[]>(1);
  savedSearches$: ReplaySubject<SavedSearch> = new ReplaySubject<SavedSearch>();

  private readonly createUserUrl             = environment.apiBaseUrl + '/:cu/users/create';
  private readonly favoriteVehiclesUrl       = environment.apiBaseUrl + '/:cu/users/favoriteVehicles/:id';
  private readonly getCarFaxUrl              = environment.apiBaseUrl + '/:cu/users/getCarFax/:id';
  private readonly getLoggedInSessionUserUrl = environment.apiBaseUrl + '/:cu/users/sessionData';
  private readonly loginUrl                  = environment.apiBaseUrl + '/:cu/users/login';
  private readonly resetPasswordUrl          = environment.apiBaseUrl + '/:cu/users/reset';
  private readonly saveSearchUrl             = environment.apiBaseUrl + '/:cu/users/savedSearch';
  private readonly saveSearchRemoveUrl       = environment.apiBaseUrl + '/:cu/users/savedSearch/:id';
  private readonly saveNewPasswordUrl        = environment.apiBaseUrl + '/:cu/users/saveNewPassword';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) { }

  createUser(loginData: LoginData): Observable<string> {
    return this.http.post<string>(this.createUserUrl.replace(':cu', this.configService.cuShortName), loginData);
  }

  favoriteVehicleRemove(classifiedAdID: string): Observable<boolean> {
    return this.http.delete<boolean>(this.favoriteVehiclesUrl.replace(':cu', this.configService.cuShortName).replace(':id', classifiedAdID));
  }

  favoriteVehicleSave(classifiedAdID: string): Observable<boolean> {
    return this.http.put<boolean>(this.favoriteVehiclesUrl.replace(':cu', this.configService.cuShortName).replace(':id', classifiedAdID), {});
  }

  getCarFax(classifiedAdID: string): Observable<CarFaxResponse> {
    return this.http.get<CarFaxResponse>(this.getCarFaxUrl.replace(':cu', this.configService.cuShortName).replace(':id', classifiedAdID));
  }

  getLoggedInSessionUser(): Observable<SessionUserResponse> {
    return this.http.get<SessionUserResponse>(this.getLoggedInSessionUserUrl.replace(':cu', this.configService.cuShortName));
  }

  login(loginData: LoginData): Observable<SessionUserResponse> {
    return this.http.post<SessionUserResponse>(this.loginUrl.replace(':cu', this.configService.cuShortName), loginData);
  }

  resetPassword(emailAddress: string): Observable<string> {
    return this.http.post<string>(this.resetPasswordUrl.replace(':cu', this.configService.cuShortName), {emailAddress: emailAddress});
  }

  savedSearchRemove(savedSearchID: number): Observable<boolean> {
    return this.http.delete<boolean>(this.saveSearchRemoveUrl.replace(':cu', this.configService.cuShortName).replace(':id', savedSearchID.toString()));
  }

  saveSearch(searchParameters: Object): Observable<number> {
    return this.http.post<number>(this.saveSearchUrl.replace(':cu', this.configService.cuShortName), {search: searchParameters});
  }

  saveNewPassword(resetKey: string, password: string): Observable<string> {
    return this.http.post<string>(this.saveNewPasswordUrl.replace(':cu', this.configService.cuShortName), {resetKey: resetKey, password: password});
  }

}
