<ng-container [ngSwitch]="rating">
  <!-- <div *ngSwitchCase="'Great'" class="price-rating">🔥&nbsp;&nbsp;Great Price&nbsp;&nbsp;🔥</div>
  <div *ngSwitchCase="'Good'" class="price-rating">Good Price&nbsp;&nbsp;👍</div>
  <div *ngSwitchCase="'Fair'" class="price-rating">Fair Price</div> -->

<div class="layout2-rating-svg">
  <div *ngSwitchCase="'Great'" class="price-rating-box">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="1500.000000pt" height="1352.000000pt" viewBox="0 0 1500.000000 1352.000000"
      preserveAspectRatio="xMidYMid meet">

      <defs>
        <!-- <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:#0627a0;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#0057fd;stop-opacity:1" />
        </linearGradient> -->
        <linearGradient id="grad1" x1="100%" y1="100%" x2="30%" y2="30%">
            <stop class="price-rating-gradient1" offset="0%" stop-opacity="1" />
            <stop class="price-rating-gradient2" offset="100%" stop-opacity="1" />
          </linearGradient>
      </defs>


      <g transform="translate(0.000000,1352.000000) scale(0.100000,-0.100000)"
      fill="url(#grad1)" stroke="none" class="gradient-fill">
          <path d="M2920 13129 c-291 -39 -482 -95 -700 -203 -740 -369 -1315 -1227
          -1639 -2446 -18 -69 -80 -349 -95 -427 l-5 -33 2323 0 2324 0 6 -32 c33 -185
          85 -759 111 -1243 27 -488 33 -1707 19 -3570 -4 -484 -4 -918 0 -965 l7 -85
          271 -215 c149 -118 341 -271 427 -340 86 -69 244 -195 351 -280 107 -85 373
          -296 590 -469 217 -173 522 -416 678 -540 156 -124 343 -273 415 -331 73 -58
          251 -200 397 -315 331 -263 606 -482 965 -768 154 -123 360 -288 458 -365 97
          -78 181 -142 185 -142 5 0 51 37 103 83 52 45 148 129 214 187 239 208 850
          744 855 750 3 3 55 48 115 100 118 102 316 275 430 375 39 34 288 253 554 486
          471 411 573 501 1207 1055 165 144 362 317 439 384 77 68 197 173 267 234
          l127 111 1 405 c0 223 5 927 10 1564 5 699 5 1232 0 1340 -45 898 -85 1374
          -151 1821 -79 535 -185 999 -331 1445 -118 363 -291 765 -456 1063 -45 81 -82
          150 -82 152 0 2 -12 21 -26 42 -14 21 -30 47 -34 58 -5 11 -41 68 -82 127
          -309 449 -658 782 -993 948 l-90 45 -4550 1 c-2558 1 -4578 -2 -4615 -7z"/>
      </g>
    </svg>

    <div class="price-rating"><p class="l2-price-rating-text">Great<br>Price</p><p class="l2-price-rating-icon">🔥&nbsp;🔥&nbsp;🔥</p></div>
  </div>
  <div *ngSwitchCase="'Good'" class="price-rating-box">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="1500.000000pt" height="1352.000000pt" viewBox="0 0 1500.000000 1352.000000"
      preserveAspectRatio="xMidYMid meet">

      <defs>
        <linearGradient id="grad1" x1="100%" y1="100%" x2="30%" y2="30%">
          <stop class="price-rating-gradient1" offset="0%" stop-opacity="1" />
          <stop class="price-rating-gradient2" offset="100%" stop-opacity="1" />
        </linearGradient>
      </defs>

      <g transform="translate(0.000000,1352.000000) scale(0.100000,-0.100000)"
      fill="url(#grad1)" stroke="none" class="gradient-fill">
          <path d="M2920 13129 c-291 -39 -482 -95 -700 -203 -740 -369 -1315 -1227
          -1639 -2446 -18 -69 -80 -349 -95 -427 l-5 -33 2323 0 2324 0 6 -32 c33 -185
          85 -759 111 -1243 27 -488 33 -1707 19 -3570 -4 -484 -4 -918 0 -965 l7 -85
          271 -215 c149 -118 341 -271 427 -340 86 -69 244 -195 351 -280 107 -85 373
          -296 590 -469 217 -173 522 -416 678 -540 156 -124 343 -273 415 -331 73 -58
          251 -200 397 -315 331 -263 606 -482 965 -768 154 -123 360 -288 458 -365 97
          -78 181 -142 185 -142 5 0 51 37 103 83 52 45 148 129 214 187 239 208 850
          744 855 750 3 3 55 48 115 100 118 102 316 275 430 375 39 34 288 253 554 486
          471 411 573 501 1207 1055 165 144 362 317 439 384 77 68 197 173 267 234
          l127 111 1 405 c0 223 5 927 10 1564 5 699 5 1232 0 1340 -45 898 -85 1374
          -151 1821 -79 535 -185 999 -331 1445 -118 363 -291 765 -456 1063 -45 81 -82
          150 -82 152 0 2 -12 21 -26 42 -14 21 -30 47 -34 58 -5 11 -41 68 -82 127
          -309 449 -658 782 -993 948 l-90 45 -4550 1 c-2558 1 -4578 -2 -4615 -7z"/>
      </g>
    </svg>

    <div class="price-rating"><p class="l2-price-rating-text">Good<br>Price</p><p class="l2-price-rating-icon">👍</p></div>
  </div>
  <div *ngSwitchCase="'Fair'" class="price-rating-box">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="1500.000000pt" height="1352.000000pt" viewBox="0 0 1500.000000 1352.000000"
      preserveAspectRatio="xMidYMid meet">

      <defs>
        <linearGradient id="grad1" x1="100%" y1="100%" x2="30%" y2="30%">
          <stop class="price-rating-gradient1" offset="0%" stop-opacity="1" />
          <stop class="price-rating-gradient2" offset="100%" stop-opacity="1" />
        </linearGradient>
      </defs>

      <g transform="translate(0.000000,1352.000000) scale(0.100000,-0.100000)"
      fill="url(#grad1)" stroke="none" class="gradient-fill">
          <path d="M2920 13129 c-291 -39 -482 -95 -700 -203 -740 -369 -1315 -1227
          -1639 -2446 -18 -69 -80 -349 -95 -427 l-5 -33 2323 0 2324 0 6 -32 c33 -185
          85 -759 111 -1243 27 -488 33 -1707 19 -3570 -4 -484 -4 -918 0 -965 l7 -85
          271 -215 c149 -118 341 -271 427 -340 86 -69 244 -195 351 -280 107 -85 373
          -296 590 -469 217 -173 522 -416 678 -540 156 -124 343 -273 415 -331 73 -58
          251 -200 397 -315 331 -263 606 -482 965 -768 154 -123 360 -288 458 -365 97
          -78 181 -142 185 -142 5 0 51 37 103 83 52 45 148 129 214 187 239 208 850
          744 855 750 3 3 55 48 115 100 118 102 316 275 430 375 39 34 288 253 554 486
          471 411 573 501 1207 1055 165 144 362 317 439 384 77 68 197 173 267 234
          l127 111 1 405 c0 223 5 927 10 1564 5 699 5 1232 0 1340 -45 898 -85 1374
          -151 1821 -79 535 -185 999 -331 1445 -118 363 -291 765 -456 1063 -45 81 -82
          150 -82 152 0 2 -12 21 -26 42 -14 21 -30 47 -34 58 -5 11 -41 68 -82 127
          -309 449 -658 782 -993 948 l-90 45 -4550 1 c-2558 1 -4578 -2 -4615 -7z"/>
      </g>
    </svg>

    <div class="price-rating"><p class="l2-price-rating-text">Fair<br>Price</p><p class="l2-price-rating-icon"></p></div>
  </div>
</div>
</ng-container>