import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@services/config.service';
import { ProductType, ProtectionProduct } from '@models/vehicle-listing';

import { environment } from '@environments/environment';

export interface LeadData {
  listingID: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  comment: string;
  checkDealer: boolean;
  checkLoan: boolean;
  checkBalloonLoan: boolean;
  monthlyPaymentParameters: MonthlyPaymentParameters;
  leadCategory: LeadCategory;
}

export interface SoftLeadData {
  question: string;
  customQuestion: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface CarSaleRegistrationData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

// this is similar to the PaymentParameters interface defined in the payment service,
// except here we store the interest rate and specific product quotes directly
export interface MonthlyPaymentParameters {
  interestRate?: number; // if this MonthlyPaymentParameters represents the parameters used at the time of a search, interestRate is only set if rateServiceType is "userInput"
  termMonths: number;
  rateServiceType: "userInput" | "creditScoreTable" | "autoXpress";
  creditTierId?: number; // used for AFG leads
  tradeInValue: number;
  otherDownPayment: number;
  tradeInSalesTaxCredit: boolean;
  salesTaxRate: number;
  transactionFees: number;
  balloonLoanFee?: number;
  productsEnabled: Array<ProductType>; // list of which products are enabled
  productQuotes: Array<ProtectionProduct>; // actual quotes for the specific products that are enabled; this is only applicable when the MonthlyPaymentParameters is associated with a specific listing (if it isn't then this will be an empty array)
}

// These strings should match the object names on the server-side
export type LeadCategory = "DealerContactLead" | "VehicleHistoryRequestLead";

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  private readonly submitLeadUrl                = environment.apiBaseUrl + '/v2/cbs/:cu/submitLead';
  private readonly submitSoftLeadUrl            = environment.apiBaseUrl + '/:cu/sweepstakes/:id/submit';
  private readonly submitCarSaleRegistrationUrl = environment.apiBaseUrl + '/:cu/virtualCarSales/:id/register';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) { }

  submit(leadData: LeadData) {
    return this.http.post(this.submitLeadUrl.replace(':cu', this.configService.cuShortName), leadData);
  }

  submitSoftLead(sweepstakesId: number, softLeadData: SoftLeadData) {
    return this.http.post(this.submitSoftLeadUrl.replace(':cu', this.configService.cuShortName).replace(':id', sweepstakesId.toString()), softLeadData);
  }

  submitCarSaleRegistrationLead(carSalesId: number, carSaleRegistrationData: CarSaleRegistrationData) {
    return this.http.post(this.submitCarSaleRegistrationUrl.replace(':cu', this.configService.cuShortName).replace(':id', carSalesId.toString()), carSaleRegistrationData);
  }

}
