import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';

import { AFGService } from '@services/afg.service';
import { ConfigService } from '@services/config.service';
import { PaymentService } from '@services/payment.service';
import { PhoneFormatter } from '@cbs-common/phone-formatter';
import { ReferrerMessagingService } from '@services/referrer-messaging.service';
import { UserSessionService } from '@services/user-session.service';
import { LeadData, LeadService } from '@services/lead.service';
import { SearchService } from '@services/search.service';
import { InventorySource, VehicleListing } from '@models/vehicle-listing';

import { environment } from '@environments/environment';
import phoneNumberValidator from '@cbs-common/phone-number-validator';

@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.sass']
})
export class LeadFormComponent implements OnInit {

  @ViewChild('prefillCommentSelect', {static: false}) prefillCommentSelect!: ElementRef;

  @Input() isVehicleHistoryRequest?: boolean;
  @Input() set listing(l: VehicleListing) {
    this._listing = l;

    this.isLeadSubmitted = false;
    this.leadSubmitted.next(false);

    // Prefill the comment area with text related to the current vehicle
    // The first time the page loads, our ViewChild properties may not be populated yet because those elements are only
    // displayed on the view after the listing loads (which only happens after this function completes).
    // Therefore, we stick the update inside an interval and watch for the ViewChilds to be set.
    const updateInterval = setInterval(() => {
      if (this.prefillCommentSelect) {
        clearInterval(updateInterval);

        this.prefillComment(this.prefillCommentSelect.nativeElement);
      }
    }, 100);
  }
  @Output() leadSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  leadForm!: FormGroup;
  leadType: number = 0; // 0 means regular lead, 1 means completely hidden lead, 2 means no partners lead
  submitBtnLabel!: string;
  isLeadSubmitted!: boolean;

  get listing() {
    return this._listing;
  }

  get shouldShowApplyButton() {
    // We can show the apply button if it is turned on in the configuration settings and if
    // the referring window is available for communicating the vehicle information back
    const shouldShow = this.configService.showApplyBtn && this.referrerMessagingService.isMessagingChannelOpen;

    // We will also always keep the button visible if it was shown at least once. This is so that it
    // does not dissappear before the user's eyes if the referrer messaging channel goes away, which would
    // be very confusing. (Instead, we will give the user an error explaining the situation if they click
    // the button and the messaging channel has gone away.)
    if (shouldShow) {
      this._leaveApplyButtonShown = true;
    }

    return shouldShow || this._leaveApplyButtonShown;
  }

  private _leaveApplyButtonShown!: boolean;
  private _listing!: VehicleListing;

  constructor(
    public afg: AFGService,
    public configService: ConfigService,
    public paymentService: PaymentService,
    public userSessionService: UserSessionService,
    private formBuilder: FormBuilder,
    private leadService: LeadService,
    private referrerMessagingService: ReferrerMessagingService,
    private searchService: SearchService,
  ) { }

  ngOnInit() {
    let phoneNumberValidators;
    if (this.listing.moreDetail && this.listing.moreDetail.leadRequirements.phoneNumber == 'IsRequired') {
      phoneNumberValidators = [phoneNumberValidator, Validators.required];
    } else {
      phoneNumberValidators = [phoneNumberValidator];
    }
    let zipCodeValidators: ValidatorFn[];
    if (this.listing.moreDetail && this.listing.moreDetail.leadRequirements.zipCode == 'IsRequired') {
      zipCodeValidators = [Validators.required];
    } else {
      zipCodeValidators = [];
    }

    this.leadForm = this.formBuilder.group({
      firstName: [this.userSessionService.firstName, [Validators.required]],
      lastName: [this.userSessionService.lastName, [Validators.required]],
      emailAddress: [this.userSessionService.emailAddress, [Validators.required, Validators.email]],
      phoneNumber: [this.userSessionService.phoneNumber, phoneNumberValidators],
      zipCode: [this.userSessionService.zipCode, zipCodeValidators],
      comment: [''],
      checkBalloonLoan: [this.shouldEnableBalloonLoanInput()],
    });
    this.leadForm.controls['firstName'].valueChanges.subscribe(_ => this.updateLeadType());
    this.leadForm.controls['lastName'].valueChanges.subscribe(_ => this.updateLeadType());
    this.leadForm.controls['checkBalloonLoan'].valueChanges.subscribe(value => {
      this.userSessionService.interestedInAFG = value;
    });

    this.updateLeadType();
  }

  chooseThisVehicle() {
    if (this.referrerMessagingService.isMessagingChannelOpen) {
      this.referrerMessagingService.sendVehicleSelection(this._listing.classifiedAdID);
    } else {
      window.parent.postMessage({eventType: 'alert', alertMessage: 'We are unable to communicate with the loan application service. This can happen if you have been logged out of home banking or have closed the home banking window. To try again, close this window, log back in to home banking, then return to this page.'}, environment.apiBaseUrl);
      this._leaveApplyButtonShown = false;
    }
  }

  clearForm() {
    this.leadForm.reset({
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      comment: '',
      checkBalloonLoan: this.shouldEnableBalloonLoanInput(),
    });

    this.prefillComment(this.prefillCommentSelect.nativeElement);
  }

  doSubmitLead() {
    if (this.leadForm.status === 'VALID') {
      // Prepare the lead data object
      const lead: LeadData = this.leadForm.value;
      const paymentParams = this.paymentService.params$.value;
      const rate = paymentParams.rateService.getRate(this.listing.isNew, this.listing.year, paymentParams.preferredTermMonths);
      lead.listingID = this._listing.id;
      lead.leadCategory = this.isVehicleHistoryRequest ? "VehicleHistoryRequestLead" : "DealerContactLead";
      lead.monthlyPaymentParameters = {
        interestRate: rate.interestRate,
        termMonths: paymentParams.preferredTermMonths,
        rateServiceType: paymentParams.rateService.kind,
        tradeInValue: paymentParams.tradeInValue,
        otherDownPayment: paymentParams.otherDownPayment,
        tradeInSalesTaxCredit: paymentParams.tradeInSalesTaxCredit,
        salesTaxRate: paymentParams.salesTaxRate,
        transactionFees: paymentParams.transactionFees,
        balloonLoanFee: this.configService.balloonLoanFee,
        productsEnabled: [...paymentParams.productsEnabled],
        productQuotes: this.listing.products.filter(product => paymentParams.productsEnabled.has(product.productType)),
      };

      // AFG leads also need the creditTierId since that is transmitted to AFG's API with the lead data
      if (paymentParams.rateService.kind === "creditScoreTable") {
        lead.monthlyPaymentParameters.creditTierId = paymentParams.rateService.getCurrentCreditTierId(this._listing.isNew);
      }

      // Update the view so that it displays information as through the lead was submitted successfully
      // We assume the lead submission will be successful so that we can show the user the results immediately
      // instead of waiting for a reply from the server (which can take a few seconds to process the lead)
      this.isLeadSubmitted = true;
      this.leadSubmitted.next(true);

      // Update the locally stored user session so that the next lead form is auto-populated
      this.userSessionService.currentUser = lead;

      this.leadService.submit(lead).subscribe({
        error: () => {
          window.parent.postMessage({eventType: 'alert', alertMessage: 'Unable to communicate with the server. Your message was not transmitted. Please check your Internet connection then try again.'}, environment.apiBaseUrl);

          // Update the view so that the user can easily submit the lead again
          this.isLeadSubmitted = false;
          this.leadSubmitted.next(false);
        }
      });
    }
  }

  // A helper function is needed here since we cannot refer to the InventorySource enum directly in the component HTML
  isTrueCarDealer() {
    return this.configService.useTrueCarLeadDelivery &&
      this._listing?.moreDetail?.inventorySource === InventorySource.TrueCarInventorySource &&
      !this._listing?.moreDetail?.prioritized;
  }

  formatPhone(phone: string) {
    return PhoneFormatter.format(phone);
  }

  formatPhoneInLeadForm() {
    const leadForm = this.leadForm.get('phoneNumber');
    if (leadForm) {
      const val = leadForm.value;
      const formatted = PhoneFormatter.format(val);
      if (formatted !== '' && formatted !== val) {
        leadForm.setValue(formatted);
      }
    }
  }

  prefillComment(select: EventTarget | null) {
    const comment = this.leadForm.get('comment');
    if (comment && select instanceof HTMLSelectElement) {
      const vehicleDescription = this._listing.year + ' ' + this._listing.make + ' ' + this._listing.model + ' ' + this._listing.trim;

      let price = 'sale';
      if (this._listing.price > 0) {
        price = formatCurrency(this._listing.price, 'en-US', '$', 'USD', '1.0-0');
      }

      const activeOption = select.options[select.selectedIndex];
      const prefill = (activeOption.getAttribute('data-prefill') ?? '')
        .replace("%VEHICLE%", vehicleDescription)
        .replace("%PRICE%", price);

      this.submitBtnLabel = activeOption.getAttribute('data-submit-label') ?? 'Submit';

      comment.setValue(prefill);
    }
  }

  private shouldEnableBalloonLoanInput() {
    const isEligible = this.afg.mayBeEligible(this.listing, true);
    const interestedInAFG = this.userSessionService.interestedInAFG;
    return isEligible && interestedInAFG;
  }

  private updateLeadType() {
    // The logic and name values here should match ProspectLead.isTestLead
    setTimeout(() => { // Run this in a setTimeout so that the values are post-update
      if (
        this.leadForm.value.firstName.toLowerCase().indexOf('xxxxxx') !== -1 ||
        this.leadForm.value.lastName.toLowerCase().indexOf('xxxxxx') !== -1
      ) {
        this.leadType = 2;
      } else if (
        this.leadForm.value.firstName.toLowerCase().indexOf('zzzzzz') !== -1 ||
        this.leadForm.value.lastName.toLowerCase().indexOf('zzzzzz') !== -1
      ) {
        this.leadType = 1;
      } else {
        this.leadType = 0;
      }
    });
  }

}
