import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AFGService } from '@services/afg.service';
import { ConfigService } from '@services/config.service';
import { CreditScoreTableRateService, PaymentCalculationResult, PaymentParameters, PaymentService, UserInputRateService } from '@services/payment.service';
import { UserSessionService } from '@services/user-session.service';
import { ProductType, ProtectionProduct, VehicleListing } from '@models/vehicle-listing';

import { environment } from '@environments/environment';
import { Residuals } from '@models/residuals';

@Component({
  selector: 'app-monthly-payment',
  templateUrl: './monthly-payment.component.html',
  styleUrls: ['./monthly-payment.component.sass']
})
export class MonthlyPaymentComponent implements OnChanges, OnInit {

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe
  isUsingFallbackRate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  payment$!: Observable<number>;
  paymentParameterForm!: FormGroup;
  showAdvancePayments!: boolean;
  showCosts!: boolean;
  showProducts!: boolean;
  showVehicle!: boolean;
  balloonLoanIneligible!: boolean;
  highPricedVehicleSurcharge!: number;
  hasHighPricedVehicleSurcharge!: boolean;

  @Input() listing!: VehicleListing;
  @Input() conventionalLoan: boolean = true;

   private readonly balloonPaymentService = new PaymentService();

  constructor(
    public afg: AFGService,
    public configService: ConfigService,
    public paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userSessionService: UserSessionService,
    private cd: ChangeDetectorRef,
  ) {
    this.paymentParameterForm = this.formBuilder.group({
      creditTierScore: [""],
      rate: ["1.49"],
      term: ["60"],
      downPayment: ["1000"],
      tradeIn: ["0"],
      salesTaxRate: ["0"],
      transactionFees: ["750"],
      tradeInSalesTaxCredit: [true],
    });

    // If the user changes the payment calculation parameters, update the user session service with
    // the new parameters so that they are saved in the user's session and automatically restored
    // when the user leaves and returns
    this.paymentParameterForm.valueChanges
      .pipe(map(formParams => {
        // Convert the form parameters into corresponding PaymentParameters

        switch (this.paymentService.params$.value.rateService.kind) {
          case "userInput":
            // Instantiate a new UserInputRateService instead of editing the interest rate in the existing UserInputRateService.
            // This ensures that we are not editing the value that is shared elsewhere (such as in the UserSessionService).
            this.paymentService.params$.value.rateService = new UserInputRateService(+formParams.rate);
            break;
          case "creditScoreTable":
            // Instantiate a new CreditScoreTableRateService instead of editing the interest rate in the existing CreditScoreTableRateService.
            // This ensures that we are not editing the value that is shared elsewhere (such as in the UserSessionService).
            const currentRateService = this.paymentService.params$.value.rateService;
            this.paymentService.params$.value.rateService = new CreditScoreTableRateService(
              +formParams.rate,
              +formParams.creditTierScore,
              currentRateService.creditTiers,
              currentRateService.isBalloonLoan,
              currentRateService.rateRows,
              currentRateService.availableTerms,
            );
            break;
          case "autoXpress":
            // nothing to do
            break;
          default:
            const _exhaustiveCheck: never = this.paymentService.params$.value.rateService;
        }

        return {
          rateService: this.paymentService.params$.value.rateService,
          preferredTermMonths: +formParams.term,
          tradeInValue: +formParams.tradeIn,
          otherDownPayment: +formParams.downPayment,
          tradeInSalesTaxCredit: formParams.tradeInSalesTaxCredit,
          salesTaxRate: +formParams.salesTaxRate,
          transactionFees: +formParams.transactionFees,
          productsEnabled: this.paymentService.params$.value.productsEnabled,
        };
      }))
      .subscribe(paymentParams => {
        // Only pass the paymentParams through to the userSessionService if there has been some substantive change to the parameter values.
        // The purpose of this is to prevent passing through parameter changes that consist only of the user adding a decimal point to
        // one of the numerical fields. If we passed the params through at that point, it would trigger a patchValue on the form which would
        // effectively prevent the user from entering the decimal point by resetting the cursor position on the input box.
        if (JSON.stringify(this.userSessionService.paymentParams) !== JSON.stringify(paymentParams)) {
          this.userSessionService.paymentParams = paymentParams;
        }
      });

    this.paymentService.params$.subscribe((paymentSettings: PaymentParameters) => {
      // Don't emit an event below (emitEvent: false). We only want the paymentParameterForm.valueChanges listener above to capture
      // user-driven events, not events like this which may be happening on page load as user settings or client settings are loaded onto the page.
      switch (paymentSettings.rateService.kind) {
        case "userInput": {
          this.paymentParameterForm.patchValue(
            {
              rate: paymentSettings.rateService.interestRate,
              term: paymentSettings.preferredTermMonths,
              downPayment: paymentSettings.otherDownPayment,
              tradeIn: paymentSettings.tradeInValue,
              tradeInSalesTaxCredit: paymentSettings.tradeInSalesTaxCredit,
              salesTaxRate: paymentSettings.salesTaxRate,
              transactionFees: paymentSettings.transactionFees,
            },
            {emitEvent: false}
          );
          return;
        }
        case "creditScoreTable": {
          this.paymentParameterForm.patchValue(
            {
              rate: paymentSettings.rateService.fallbackInterestRate,
              creditTierScore: paymentSettings.rateService.currentCreditTierScore,
              term: paymentSettings.preferredTermMonths,
              downPayment: paymentSettings.otherDownPayment,
              tradeIn: paymentSettings.tradeInValue,
              tradeInSalesTaxCredit: paymentSettings.tradeInSalesTaxCredit,
              salesTaxRate: paymentSettings.salesTaxRate,
              transactionFees: paymentSettings.transactionFees,
            },
            {emitEvent: false}
          );
          return;
        }
        case "autoXpress":
          this.paymentParameterForm.patchValue(
            {
              rate: paymentSettings.rateService.fallbackInterestRate,
              term: paymentSettings.preferredTermMonths,
              downPayment: paymentSettings.otherDownPayment,
              tradeIn: paymentSettings.tradeInValue,
              tradeInSalesTaxCredit: paymentSettings.tradeInSalesTaxCredit,
              salesTaxRate: paymentSettings.salesTaxRate,
              transactionFees: paymentSettings.transactionFees,
            },
            {emitEvent: false}
          );
          return;
        default:
          const _exhaustiveCheck: never = paymentSettings.rateService;
      }
    });
  }

  ngOnInit() {
    // Show the products section expanded if there are any products applicable to this listing.
    // This is to ensure the user sees the product quotes are part of the monthly payment amount so they can turn them off if they are undesired.
    if (this.listing.products.length > 0) {
      this.toggleShowProducts(false);
    }

    // If this is not for a conventional loan, we need to switch to a different payment service so that 
    // the balloon loan rate sheet can be used for calculations. We also still want that new service to update
    // if the global payment service has its parameters changed (e.g., by the user editing their loan settings),
    // so we subscribe to the global payment service's parameter observable.
    if (!this.conventionalLoan) {
      this.paymentService.params$
        .subscribe(params => {
          // Make sure we have the right type of RateService
          // If the user is directly loading this page, the initial RateService may be the default UserInput kind.
          // We'll ignore that and wait for the creditScoreTable to load in next.
          if (params.rateService.kind === "creditScoreTable") {
            // Set up the balloonPaymentService to use the same parameters as the regular global payment service, except for picking the balloon loan
            // rates instead of the conventional loan rates (like the global payment service will do)
            const balloonPaymentServiceParams = {...params};
            const rateService = balloonPaymentServiceParams.rateService as CreditScoreTableRateService;
            balloonPaymentServiceParams.rateService = new CreditScoreTableRateService(
              rateService.fallbackInterestRate,
              rateService.currentCreditTierScore,
              rateService.creditTiers,
              true, // isBalloonLoan <-- this is what we change to enable use of the balloon loan rate sheet
              rateService.rateRows,
              rateService.availableTerms,
            );
            this.balloonPaymentService.params$.next(balloonPaymentServiceParams);
          }
        });
    }
  }

  updateBalloonLoanEligibility(eligible: boolean) {
    this.balloonLoanIneligible = !eligible;
    this.cd.detectChanges();
  }

  ngOnChanges() {
    if (this.conventionalLoan) {
      this.payment$ = this.paymentService.params$.pipe(
        // This is a convention loan payment calculation. Use 0 as the endingBalance and do not provide a balloon loan fee.
        map(() => {
          const calcResult = this.paymentService.calculatePayment(this.listing.price, this.calcFixedProductTotal(), this.listing.isNew, this.listing.year, 0, this.calcMonthlyProductTotal());
          this.updateIsUsingFallbackRate(calcResult);
          return calcResult.amount;
        })
      );
    } else {
      this.payment$ = this.paymentService.params$.pipe(
        // This is a balloon loan payment calculation. Use the residual as the endingBalance and do provide the balloon loan fee obtained from the config service.
        mergeMap(() => {
          if (this.listing.mileage === null && !this.listing.isNew) {
            // Used vehicles with no odometer reading are not eligible for quoting a balloon loan payment online
            this.updateBalloonLoanEligibility(false);
            return of(0); // return 0 wrapped in an Observable
          } else {
            return this.afg.getResiduals$(this.listing).pipe(
              map((residuals: Residuals | null) => {
                if (residuals === null) {
                  // A null response means this vehicle is not eligible for quoting a balloon loan payment online
                  this.updateBalloonLoanEligibility(false);
                  return 0;
                } else {
                  this.updateBalloonLoanEligibility(true);
                  const residual = +residuals[`residual${this.paymentService.params$.value.preferredTermMonths}mo${this.afg.DEFAULT_MILEAGE}miles`];
                  this.highPricedVehicleSurcharge = +residuals[`hpvs${this.paymentService.params$.value.preferredTermMonths}mo${this.afg.DEFAULT_MILEAGE}miles`];
                  this.hasHighPricedVehicleSurcharge = Number.isFinite(this.highPricedVehicleSurcharge) && this.highPricedVehicleSurcharge > 0;
                  if (this.hasHighPricedVehicleSurcharge) {
                    const paymentParameters = {...this.balloonPaymentService.params$.value};
                    const conventionalPaymentParameters = {...this.paymentService.params$.value};
                    paymentParameters.otherDownPayment = conventionalPaymentParameters.otherDownPayment - this.highPricedVehicleSurcharge;
                    this.balloonPaymentService.params$.next(paymentParameters);
                  }
                  const calcResult = this.balloonPaymentService.calculatePayment(this.listing.price, this.calcFixedProductTotal(), this.listing.isNew, this.listing.year, residual, 0, this.configService.balloonLoanFee);
                  this.updateIsUsingFallbackRate(calcResult);
                  return calcResult.amount;
                }
              })
            );
          }
        })
      );
    }
  }

  allNonblockedProductsEnabled(): boolean {
    // Every product should either be enabled or being blocked by an enabled product
    const enabledProducts: Array<ProtectionProduct> = this.enabledProducts();

    const enabledProductTypes: Array<ProductType> = enabledProducts.map(p => p.productType);

    const blockedByEnabledTypes: Array<ProductType> = enabledProducts.reduceRight((arr, p) => { // this reduce is a flatMap
      return p.blocks
             ? arr.concat(p.blocks)
             : arr;
    }, new Array<ProductType>());

    return this.listing.products.reduceRight((allEnabled, product) => {
      const thisIsEnabled = enabledProductTypes.indexOf(product.productType) !== -1;
      const thisIsBlockedByEnabledProduct = blockedByEnabledTypes.indexOf(product.productType) !== -1;
      return allEnabled && (thisIsEnabled || thisIsBlockedByEnabledProduct);
    }, true);
  }

  breakLines(str: string): string {
    return str.replace("\n", "<br>");
  }

  calcFixedProductTotal(): number {
    return this.enabledProducts().reduceRight((sum, product) => {
      return product.priceData.kind === "FixedProductPrice"
             ? product.priceData.amount + sum
             : sum;
    }, 0);
  }

  calcMonthlyProductTotal(): number {
    return this.enabledProducts().reduceRight((sum, product) => {
      return product.priceData.kind === "PrincipalBasedMonthlyRateProductPrice"
             ? product.priceData.amountPerMillion + sum
             : sum;
    }, 0);
  }

  calcTotal(): number {
    const paymentParams = this.paymentService.params$.value;
    const salesTax = paymentParams.tradeInSalesTaxCredit
                     ? (this.listing.price - paymentParams.tradeInValue) * paymentParams.salesTaxRate/100
                     : this.listing.price * paymentParams.salesTaxRate/100;
    const highPricedVehicleSurcharge = Number.isFinite(this.highPricedVehicleSurcharge) ? this.highPricedVehicleSurcharge : 0; // check to be sure highPricedVehicleSurcharge isn't undefined
    return this.listing.price + salesTax + paymentParams.transactionFees - paymentParams.otherDownPayment - paymentParams.tradeInValue + this.calcFixedProductTotal() + highPricedVehicleSurcharge;
  }

  getMonthlyComponent(amount: number) {
    const preferredTermMonths = this.paymentService.params$.value.preferredTermMonths;
    const interestRate = this.paymentService.params$.value.rateService.getRate(this.listing.isNew, this.listing.year, preferredTermMonths).interestRate;
    const monthlyRate = interestRate/100/12;
    if (monthlyRate === 0) {
      return amount / preferredTermMonths;
    } else {
      return (amount * Math.pow(1 + monthlyRate, preferredTermMonths)) / ((Math.pow(1 + monthlyRate, preferredTermMonths) - 1) / monthlyRate);
    }
  }

  getMonthlyComponentForPrincipalBasedProduct(amount: number) {
    const baseMonthlyAmount = this.paymentService.calculatePayment(this.listing.price, this.calcFixedProductTotal(), this.listing.isNew, this.listing.year, 0, 0).amount;
    const fullMonthlyAmount = this.paymentService.calculatePayment(this.listing.price, this.calcFixedProductTotal(), this.listing.isNew, this.listing.year, 0, amount).amount;
    return fullMonthlyAmount - baseMonthlyAmount;
  }

  getAllProductsMonthlyComponent() {
    return this.getMonthlyComponent(this.calcFixedProductTotal()) + this.getMonthlyComponentForPrincipalBasedProduct(this.calcMonthlyProductTotal());
  }

  getOtherCostsMonthlyComponent() {
    return this.getMonthlyComponent(this.paymentService.params$.value.transactionFees)
      + this.getMonthlyComponent(this.listing.price * this.paymentService.params$.value.salesTaxRate/100)
      + (this.highPricedVehicleSurcharge === undefined ? 0 : this.getMonthlyComponent(this.highPricedVehicleSurcharge))
      + (this.conventionalLoan                         ? 0 : this.getMonthlyComponent(this.configService.balloonLoanFee));
  }

  getRate() {
    const paymentService = (() => {
      if (this.conventionalLoan) {
        return this.paymentService;
      } else {
        return this.balloonPaymentService;
      }
    })();
    return paymentService.params$.value.rateService.getRate(this.listing.isNew, this.listing.year, this.paymentService.params$.value.preferredTermMonths).interestRate;
  }

  getTerm() {
    return this.paymentService.params$.value.preferredTermMonths;
  }

  getVSCProductDescription() {
    const vscProduct = this.listing.products.find(product => product.productType === "VSC");
    return vscProduct ? vscProduct.description : null;
  }

  goToTradeIn() {
    const tradeInUrl = environment.apiBaseUrl + '/' + this.configService.cuShortName + '/auto-values';
    window.open(tradeInUrl);
  }

  enabledProducts() {
    return this.listing.products.filter(product => this.isProductEnabled(product));
  }

  isCalculatable() {
    return this.listing.price > 0                  // If we do not know the price of the vehicle, obviously we cannot calculate a monthly payment

        && this.listing.vehicleType === 'Auto'     // We do not try to calculate monthly payments for non-Auto vehicles since the rates for those
                                                   // vehicles are often different, and we do not have those rate sheets available to us here.

        && (                                       // Finally, either...
          this.conventionalLoan                    // This must be for a conventional loan 
            || (                                   // OR
              this.afg.mayBeEligible(this.listing) // This must be for a balloon loan which we know a priori is not ineligible for online calculation
                && !this.balloonLoanIneligible     // And the server must not have indicated that it is ineligible
            )
          );
  }

  isProductEnabled(product: ProtectionProduct): boolean {
    return this.paymentService.params$.value.productsEnabled.has(product.productType);
  }

  noProductsEnabled(): boolean {
    return this.enabledProducts().length === 0;
  }

  open(content: TemplateRef<any>) {
    this.userSessionService.recordMonthlyPaymentAccess(this.listing.id.toString());
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'modal-init'});
    setTimeout(() => window.dispatchEvent(new Event('modalCreated')), 0);
  }

  openProductInfoWindow(url: string | undefined) {
    if (url) {
      window.open(url, 'ProductInfoWindow', 'width=800,height=450'); // 16:9 aspect ratio (typical for video)
    }
    return false;
  }

  showCreditScoreSelector$(): Observable<boolean> {
    // The credit score selector should be shown if the RateService being used is creditScoreTable
    // UNLESS the manual rate input is being shown for any reason
    return this.showManualRateInput$().pipe(map((showManualRateInput) => this.paymentService.params$.value.rateService.kind === 'creditScoreTable' && !showManualRateInput));
  }

  showManualRateInput$(): Observable<boolean> {
    // The manual rate input should be shown if the RateService is userInput
    // OR if the payment calculation has decided to use the fallback rate (in which case the manual rate input will allow the user to edit the fallback rate)
    if (this.paymentService.params$.value.rateService.kind === 'userInput') {
      return of(true);
    } else {
      return this.isUsingFallbackRate$;
    }
  }

  toggleShowVehicle() {
    this.showVehicle = !this.showVehicle;
    if (this.showVehicle) {
      this.userSessionService.recordMonthlyPaymentSectionAccess(this.listing.id.toString(), "Vehicle price");
    }
  }

  toggleShowProducts(recordUserAction: boolean = true) {
    this.showProducts = !this.showProducts;
    if (this.showProducts && recordUserAction) {
      this.userSessionService.recordMonthlyPaymentSectionAccess(this.listing.id.toString(), "Investment protection");
    }
  }

  toggleShowCosts() {
    this.showCosts = !this.showCosts;
    if (this.showCosts) {
      this.userSessionService.recordMonthlyPaymentSectionAccess(this.listing.id.toString(), "Other costs");
    }
  }

  toggleShowAdvancePayments() {
    this.showAdvancePayments = !this.showAdvancePayments;
    if (this.showAdvancePayments) {
      this.userSessionService.recordMonthlyPaymentSectionAccess(this.listing.id.toString(), "Up-front payments");
    }
  }


  toggleProduct(product: ProtectionProduct) {
    const paymentParams = {...this.paymentService.params$.value};
    
    let enabling;

    if (this.isProductEnabled(product)) {
      enabling = false;
      paymentParams.productsEnabled.delete(product.productType);
    } else {
      enabling = true;
      paymentParams.productsEnabled.add(product.productType);

      if (product.blocks) {
        product.blocks.forEach(productType => paymentParams.productsEnabled.delete(productType));
      }
    }

    this.userSessionService.recordMonthlyPaymentProductToggle(this.listing.id.toString(), product.productType, enabling);

    this.paymentService.params$.next(paymentParams);
  }

  private updateIsUsingFallbackRate(calcResult: PaymentCalculationResult) {
    if (calcResult.isFallbackRate !== this.isUsingFallbackRate$.value) {
      this.isUsingFallbackRate$.next(calcResult.isFallbackRate);
    }
  }

}
