<div class="bg-primary">
  <div class="top-bar d-flex justify-content-between align-items-center">
    <div (click)="copyLinkToListing($event)" class="copy-btn">
      Copy Link
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
      </svg>
    </div>
    <div class="compare-btn d-flex" [ngClass]="{'compare-is-added': compareService.isAdded(listing)}">
      <span (click)="addToCompare()">Compare<span *ngIf="compareService.listSize > 0" class="ml-1">({{compareService.listSize}})</span></span>
      <span (click)="addToCompare()" class="ml-1"><input type="checkbox" class="custom-image" [checked]="compareService.isAdded(listing)"><label></label></span>
      <span *ngIf="compareService.listSize > 1" class="compare-go-link" ngbTooltip="Go to the vehicle comparison page" (click)="compare()">Go</span>
    </div>
    <div><app-save-vehicle-button [listing]="listing"></app-save-vehicle-button></div>
  </div>
</div>