import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';

import { ConfigService } from '@services/config.service';
import { EndUsersService, SavedSearch } from '@services/end-users.service';
import { AutoXpressRateService, CreditScoreTableRateService, PaymentParameters, PaymentService, UserInputRateService, isPaymentParameters } from '@services/payment.service';
import { ProductType } from '@models/vehicle-listing';

import { environment } from '@environments/environment';

// Should match EndUser class in scala, except we do not use or need whenCreated here
export interface UserData {
  emailAddress: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
}

function isUserData(obj: any): obj is UserData {
  return obj && typeof obj === 'object' &&
    'emailAddress' in obj && (typeof obj.emailAddress === 'string' || typeof obj.emailAddress === 'undefined') &&
    'firstName' in obj && (typeof obj.firstName === 'string' || typeof obj.firstName === 'undefined') &&
    'lastName' in obj && (typeof obj.lastName === 'string' || typeof obj.lastName === 'undefined') &&
    'phoneNumber' in obj && (typeof obj.phoneNumber === 'string' || typeof obj.phoneNumber === 'undefined');
}

export enum UserSessionState {
  NullState,     // not logged in, we have no user data stored
  LoggedInState, // logged in, possibly user data is stored
  DataOnlyState, // user data is stored, but not logged in
}

const BALLOON_LOAN_CHECKBOX     = 'BalloonLoanCheckbox';
const DATA_STORAGE_KEY          = 'UserData';
const LAST_SEARCH_KEY           = 'LastSearch';
const PAYMENT_STORAGE_KEY       = 'PaymentParameters9'; // increment when parameters change so we don't try to load in obsolete parameters
const SESSION_STORAGE_KEY       = 'UserKey';
const SWEEPSTAKES_STORAGE_KEY   = 'SweepstakesEnteredMillis';
const SIGNUP_MODAL              = 'AccountSignUpModal';
const ZIP_STORAGE_KEY           = 'ZipCode';
const CAR_SALE_REGISTRATION_KEY = 'CarSaleRegisteredId';

interface AccountSignUpModalSettings {
  doNotShow: boolean;
  expiry: number;
}

function isAccountSignUpModalSettings(obj: any): obj is AccountSignUpModalSettings {
  return obj && typeof obj === 'object' && 'doNotShow' in obj && typeof obj.doNotShow === 'boolean' && 'expiry' in obj && typeof obj.expiry === 'number';
}

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  private readonly blankUserData = {
    emailAddress: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
  };

  private readonly loanAppAccessUrl = environment.apiBaseUrl + '/cbs/:cu/record/loanApp';
  private readonly sweepstakesClickUrl = environment.apiBaseUrl + '/cbs/:cu/record/sweepstakesClick';
  private readonly vtdAccessUrl        = environment.apiBaseUrl + '/cbs/:cu/record/vtd/:id';
  private readonly monthlyPaymentAccessUrl = environment.apiBaseUrl + '/cbs/:cu/record/monthly/:id';
  private readonly monthlyPaymentSectionAccessUrl = environment.apiBaseUrl + '/cbs/:cu/record/monthly/:id/:section';
  private readonly monthlyPaymentProductToggleUrl = environment.apiBaseUrl + '/cbs/:cu/record/monthly/:id/:productName/:enabling';

  private _doNotShowSignUpModal: boolean = false;
  private _paymentParams: PaymentParameters | null = null;
  private _interestedInAFG: boolean = true;
  private _state: UserSessionState = UserSessionState.NullState;
  private _sweepstakesEnteredMillis: number | null = null;
  private _userData: UserData = {...this.blankUserData};
  private _userDataRestored: boolean = false;
  private _userZipCode: string | null = null;
  private _carSaleRegisteredId: number | null = null;

  sessionKey$: Subject<string> = new ReplaySubject<string>();

  get doNotShowSignUpModal(): boolean {
    return this._doNotShowSignUpModal;
  }

  get firstName(): string {
    if (this._userData.firstName) {
      return this._userData.firstName;
    } else {
      return '';
    }
  }

  get lastName(): string {
    if (this._userData.lastName) {
      return this._userData.lastName;
    } else {
      return '';
    }
  }

  get phoneNumber(): string {
    if (this._userData.phoneNumber) {
      return this._userData.phoneNumber;
    } else {
      return '';
    }
  }

  get emailAddress(): string {
    if (this._userData.emailAddress) {
      return this._userData.emailAddress;
    } else {
      return '';
    }
  }

  get isDataOnly(): boolean {
    return this._state === UserSessionState.DataOnlyState;
  }

  get isLoggedIn(): boolean {
    return this._state === UserSessionState.LoggedInState;
  }

  get isNullState(): boolean {
    return this._state === UserSessionState.NullState;
  }

  get paymentParams(): PaymentParameters {
    if (this._paymentParams) {
      return this._paymentParams;
    } else {
      return this.paymentService.defaultParameters as PaymentParameters;
    }
  }

  get interestedInAFG(): boolean {
    return this._interestedInAFG;
  }

  get sweepstakesEntered(): boolean {
    const yesterday: number = Date.now() - 24 * 60 * 60 * 1000;
    if (this._sweepstakesEnteredMillis) {
      return this._sweepstakesEnteredMillis >= yesterday;
    } else {
      return false;
    }
  }

  get carSaleRegistered(): boolean {
    if (this.configService.virtualCarSaleConfig) {
      return this._carSaleRegisteredId == this.configService.virtualCarSaleConfig.id;
    }
    else {
      return false;
    }
  }

  get zipCode(): string {
    if (this._userZipCode === null) {
      return this.configService.defaultZip;
    } else {
      return this._userZipCode;
    }
  }

  set currentUser(userData: UserData) {
    this._userData = {...userData};
    if (this.isNullState) {
      this.state = UserSessionState.DataOnlyState;
    }
    this.storageSetItem(DATA_STORAGE_KEY, JSON.stringify(this._userData));
  }

  set doNotShowSignUpModal(val: boolean) {
    this._doNotShowSignUpModal = val;
    const accountSignUpModalSettings: AccountSignUpModalSettings = {
      doNotShow: val,
      expiry: Date.now() + 1000 * 60 * 60 * 24 * 14, // 14 days
    }
    this.storageSetItem(SIGNUP_MODAL, JSON.stringify(accountSignUpModalSettings));
  }

  set interestedInAFG(val: boolean) {
    this._interestedInAFG = val;
    this.storageSetItem(BALLOON_LOAN_CHECKBOX, val.toString());
  }

  set emailAddress(emailAddress: string) {
    const newUserData = {...this._userData};
    newUserData.emailAddress = emailAddress;
    this.currentUser = newUserData;
  }

  // At present, we only have functionality to set the last search, not to read it.
  // This is only used to make the last search parameters available to the widget(s)
  // on the client's website.
  set lastSearch(searchParameters: Object) {
    this.storageSetItem(LAST_SEARCH_KEY, JSON.stringify(searchParameters));
  }

  set state(sessionState: UserSessionState) {
    const originalLoggedInState = this.isLoggedIn;

    // Update the state
    this._state = sessionState;

    // Let the outer window know if the logged in status changed
    if (originalLoggedInState !== this.isLoggedIn) {
      window.parent.postMessage({eventType: 'loggedInStatus', isLoggedIn: this.isLoggedIn}, environment.apiBaseUrl);
    }
  }

  set paymentParams(paymentParams: PaymentParameters) {
    this._paymentParams = {...paymentParams};
    this.paymentService.params$.next(paymentParams);
    this.storageSetItem(PAYMENT_STORAGE_KEY, JSON.stringify(this._paymentParams));
  }

  set sweepstakesEntered(entered: boolean) {
    var millis = 0;
    if (entered) {
      millis = Date.now();
    }
    this._sweepstakesEnteredMillis = millis;
    this.storageSetItem(SWEEPSTAKES_STORAGE_KEY, millis.toString());
  }

  set carSaleRegistered(registered: boolean) {
    var id = 0;
    if (registered && this.configService.virtualCarSaleConfig) {
      id = this.configService.virtualCarSaleConfig.id;
    }
    this._carSaleRegisteredId = id;
    this.storageSetItem(CAR_SALE_REGISTRATION_KEY, id.toString());
  }

  set zipCode(zip: string) {
    this._userZipCode = zip;
    this.storageSetItem(ZIP_STORAGE_KEY, zip);
  }

  constructor(
    private configService: ConfigService,
    private endUserService: EndUsersService,
    private paymentService: PaymentService,
    private http: HttpClient,
  ) {
    this.storageGetItem(DATA_STORAGE_KEY, (userData: object | string | null) => {
      if (isUserData(userData)) {
        this._userData = userData;
        this._userDataRestored = true;
      }
    });

    this.storageGetItem(PAYMENT_STORAGE_KEY, (paymentParams: object | string | null) => {
      if (isPaymentParameters(paymentParams)) {
        this._paymentParams = paymentParams;

        // We need to manually reinitialize the rateService and productsEnabled properties, since they are not properly
        // created by the deserialization process. (Functions are not (de)serialized.)
        if (this._paymentParams) {
          this._paymentParams.productsEnabled = new Set<ProductType>();
          switch (this._paymentParams.rateService.kind) {
            case "userInput":
              this._paymentParams.rateService = new UserInputRateService(this._paymentParams.rateService.interestRate);
              break;
            case "creditScoreTable":
              const deserializedRateService = this._paymentParams.rateService;
              this._paymentParams.rateService = new CreditScoreTableRateService(
                deserializedRateService.fallbackInterestRate,
                deserializedRateService.currentCreditTierScore,
                 // These will be overwritten by the config service:
                deserializedRateService.creditTiers,
                deserializedRateService.isBalloonLoan,
                deserializedRateService.rateRows,
                deserializedRateService.availableTerms,
              );
              break;
            case "autoXpress":
              this._paymentParams.rateService = new AutoXpressRateService(
                this._paymentParams.rateService.fallbackInterestRate,
                this._paymentParams.rateService.newTerms,
                this._paymentParams.rateService.usedTerms,
              );
              break;
            default:
              const _exhaustiveCheck: never = this._paymentParams.rateService;
          }

          this.paymentService.params$.next(this._paymentParams);
        }
      }
    });

    this.storageGetItem(SESSION_STORAGE_KEY, (storedSessionKey: string | object | null) => {
      if (typeof storedSessionKey === 'string') {
        this.endUserService.getLoggedInSessionUser().subscribe(sessionUserResponse => {
          if (sessionUserResponse.isLoggedIn) {
            this.currentUser = sessionUserResponse.userData;
            this.endUserService.favoriteVehicles$.next(sessionUserResponse.favoriteVehicles);
            sessionUserResponse.savedSearches.forEach((savedSearch: SavedSearch) => this.endUserService.savedSearches$.next(savedSearch));
            this.state = UserSessionState.LoggedInState;
          } else if (this._userDataRestored) {
            // Can this cause a race condition?
            // If the network request for checkIfSessionLoggedIn returns before the storageGetItem call for the DATA_STORAGE_KEY returns,
            // we will not have set _userDataRestored.
            // However, a network request should never return before the simple local code execution in storageGetItem() finishes!
            // So we should be good.
            this.state = UserSessionState.DataOnlyState;
          }
        });
        this.sessionKey$.next(storedSessionKey);
      } else {
        this.newLocalSession();
      } 
    });

    this.storageGetItem(SWEEPSTAKES_STORAGE_KEY, (sweepstakesEnteredMillis: object | string | null) => {
      if (typeof sweepstakesEnteredMillis === 'string') {
        this._sweepstakesEnteredMillis = parseInt(sweepstakesEnteredMillis);
      }
    });

    this.storageGetItem(CAR_SALE_REGISTRATION_KEY, (carSaleRegisteredId: object | string | null) => {
      if (typeof carSaleRegisteredId === 'string') {
        this._carSaleRegisteredId = parseInt(carSaleRegisteredId);
      }
    });

    this.storageGetItem(SIGNUP_MODAL, (signupModalSetting: object | string | null) => {
      if (isAccountSignUpModalSettings(signupModalSetting)) {
        this._doNotShowSignUpModal = signupModalSetting.doNotShow;
      }
    });

    this.storageGetItem(BALLOON_LOAN_CHECKBOX, (balloonLoanInterestSetting: string | object | null) => {
      if (typeof balloonLoanInterestSetting === 'string') {
        this._interestedInAFG = balloonLoanInterestSetting === 'true';
      }
    });

    this.storageGetItem(ZIP_STORAGE_KEY, (zip: object | string | null) => {
      if (typeof zip === 'string') {
        this._userZipCode = zip;
      }
    });
  }

  clear() {
    // Remove any personal user data that we have stored in memory
    this._userData = {...this.blankUserData};

    // Remove any favorite vehicles, since we are logging out
    this.endUserService.favoriteVehicles$.next([]);

    // Remove all data from local session storage
    this.storageClear();

    // Assign a new ReplaySubject to the sessionKey$
    // This is needed because it was the easiest way to get the new session ID which will be 
    // created below to the HTTP auth interceptor
    this.sessionKey$ = new ReplaySubject<string>();

    // Start a new session with a new session ID
    this.newLocalSession();

    // Set the state to the fully-logged-out state, since that's what we're in now
    this.state = UserSessionState.NullState;
  }

  recordLoanAppAccess() {
    this.http.post(this.loanAppAccessUrl.replace(':cu', this.configService.cu.shortName), null).subscribe();
  }

  recordVTDAccess(classifiedAdID: string) {
    this.http.post(this.vtdAccessUrl.replace(':cu', this.configService.cu.shortName).replace(':id', classifiedAdID), null).subscribe();
  }

  recordMonthlyPaymentAccess(listingId: string) {
    this.http.post(this.monthlyPaymentAccessUrl.replace(':cu', this.configService.cu.shortName).replace(':id', listingId), null).subscribe();
  }

  recordMonthlyPaymentSectionAccess(listingId: string, sectionName: string) {
    this.http.post(this.monthlyPaymentSectionAccessUrl.replace(':cu', this.configService.cu.shortName).replace(':id', listingId).replace(':section', sectionName), null).subscribe();
  }

  recordMonthlyPaymentProductToggle(listingId: string, productName: string, enabling: boolean) {
    this.http.post(
      this.monthlyPaymentProductToggleUrl
        .replace(':cu', this.configService.cu.shortName)
        .replace(':id', listingId)
        .replace(':productName', productName)
        .replace(':enabling', enabling.toString())
      , null
    ).subscribe();
  }

  recordSoftLeadClick(question: string) {
    this.http.post(this.sweepstakesClickUrl.replace(':cu', this.configService.cu.shortName), question).subscribe();
  }

  private generateUUID() {
    return 'xxxx-xx-yx-zx-xxxxxx'.replace(/[xyz]/g, c => {
      let randomBits = window.crypto.getRandomValues(new Uint8Array(1))[0];
      if (c === 'y') {
        randomBits &= 15;  // set the 4 most significant bits to 0
        randomBits |= 64;  // set the 2nd most significant bit to 1
      } else if (c === 'z') {
        randomBits &= 63;  // set the 2 significant bits to 0
        randomBits |= 128; // set the most significant bit to 1
      }
      const str = randomBits.toString(16);
      return str.length == 1
             ? "0" + str
             : str;
    });
  }

  // The keys in localStorage get a special prefix to be sure that session values are never shared across different client sites
  private getLocalStorageKey(key: string): string {
    return this.configService.cuShortName.toUpperCase() + " " + key;
  }

  private isIframed() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  private newLocalSession() {
    // generate a new random session key
    const newSessionKey = this.generateUUID();

    // persist the new key so it gets reloaded if the user leaves and returns
    this.storageSetItem(SESSION_STORAGE_KEY, newSessionKey);

    // announce the new session key for any other components that need it (e.g., the http interceptor)
    this.sessionKey$.next(newSessionKey);
  }

  // From https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
  // We do not want to try to access the local storage API if it is not available. Doing so causes fatal errors in some browsers.
  private storageAvailable() {
    let storage;
    try {
      storage = window['localStorage'];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch(e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
  }

  private storageGetItem(key: string, callbackFnc: (itemValue: object | string | null) => void) {
    /**
     * Handles the processing of a retrieved item from storage. It checks if the item is a stringified JSON object
     * with an expiration time and processes it accordingly. If the item is expired or not a JSON object, it calls
     * the callback function with null. Otherwise, it calls the callback with the item or parsed JSON object.
     * 
     * @param {string | null} item - The item retrieved from storage, which could be null, a string, or a stringified JSON object.
     */
    function handleItem(item: string | null) {
      // Send the item to the callback
      if (item !== null && item[0] === '{') {
        // This appears to be a stringified JSON object. Check if it has an expiration time
        // and only pass it through to the callback if it has not expired.
        try {
          const itemObj = JSON.parse(item);
          if (itemObj['expiry'] && itemObj['expiry'] < Date.now()) {
            // The item has expired. Treat it as if no item was stored.
            callbackFnc(null);
          } else {
            // The item has not expired.
            callbackFnc(itemObj);
          }
        } catch (e) { // may throw a SyntaxError
          // It was not a real stringified JSON object after all. Since this means it cannot
          // have an expiration, pass it through like normal.
          callbackFnc(item);
        }
      } else {
        // This is not a stringified JSON object. Pass it to the callback as normal.
        callbackFnc(item);
      }
    }

    if (this.isIframed()) {
      // Request the item from the parent window.
      const callbackIdSent = key + Math.floor(Math.random() * (1000000000 - 0)) + 0;
      window.parent.postMessage({eventType: 'storageGetItem', key: key, callbackId: callbackIdSent}, environment.apiBaseUrl);

      // Watch for the reply from the parent
      // First build the event handling function
      const messageHandler = (event: MessageEvent<{eventType: string, callbackId: string, item: string | null}>) => {
        if (event.origin === environment.apiBaseUrl && event.data['eventType'] === 'storageGetItemCallback') {
          const callbackIdReceived = event.data['callbackId'];

          // Make sure this message is a reply to the storageGetItem request above
          if (callbackIdSent === callbackIdReceived) {
            var item = event.data['item'];

            // If the parent had no matching item in storage, check the local storage instead
            // This ensures that sessions can be tracked across client domains (as long as browser privacy settings permit that)
            if (item === null) {
              if (this.storageAvailable()) {
                const localItem = window.localStorage.getItem(this.getLocalStorageKey(key));
                if (localItem) {
                  // Yes, it is stored locally. Use this value (and also sent it up to the parent for storage there).
                  item = localItem;
                  window.parent.postMessage({eventType: 'storageSetItem', key: key, value: item}, environment.apiBaseUrl);
                }
              }
            } else {
              // Save it locally, as the item from the parent should always override over the local item, if they differ
              if (this.storageAvailable()) {
                window.localStorage.setItem(this.getLocalStorageKey(key), item);
              }
            }

            // Parse the item and send it to the callback function
            handleItem(item);

            // Disconnect this event listener as we have received the message we were waiting for
            window.removeEventListener('message', messageHandler);
          }
        }
      };
      window.addEventListener('message', messageHandler);
    } else if (this.storageAvailable()) {
      // No parent window is available. Fallback to local window storage.
      const item = window.localStorage.getItem(this.getLocalStorageKey(key));
      
      // Parse the item and send it to the callback function
      handleItem(item);
    }
  }

  private storageSetItem(key: string, value: string) {
    if (this.isIframed()) {
      window.parent.postMessage({eventType: 'storageSetItem', key: key, value: value}, environment.apiBaseUrl);
    }

    if (this.storageAvailable()) {
      window.localStorage.setItem(this.getLocalStorageKey(key), value);
    }
  }

  private storageClear() {
    if (this.isIframed()) {
      window.parent.postMessage({eventType: 'storageClear'}, environment.apiBaseUrl);
    }

    if (this.storageAvailable()) {
      window.localStorage.clear();
    }
  }

}
