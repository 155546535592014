import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { UserSessionService } from '@services/user-session.service';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-account-btns-l2',
  templateUrl: './account-btns-l2.component.html',
  styleUrls: ['./account-btns-l2.component.sass']
})
export class AccountBtnsComponentL2 implements OnInit {

  @Output() loggedOut: EventEmitter<void> = new EventEmitter<void>();

  constructor(public userSessionService: UserSessionService) { }

  ngOnInit() {
  }

  logout() {
    if (this.userSessionService.isLoggedIn) {
      window.parent.postMessage({eventType: 'alert', alertMessage: 'You have been logged out.'}, environment.apiBaseUrl);
    } else {
      window.parent.postMessage({eventType: 'alert', alertMessage: 'Your personal data has been cleared.'}, environment.apiBaseUrl);
    }

    this.userSessionService.clear();
    this.loggedOut.next();
  }

}
