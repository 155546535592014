import { Injectable } from '@angular/core';

import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(
    private toastService: ToastService,
  ) { }

  copy(msgToCopy: string, successMsg: string, $event: MouseEvent) {
    const textAreaElm = document.createElement('textarea');

    // Edge Legacy and IE11 jump the scroll to the position of the textarea,
    // so we need to position the textarea so that no jump happens.
    // We do that by putting it under the position of the mouse click.
    textAreaElm.style.position = 'absolute';
    textAreaElm.style.top = $event.clientY + 'px';
    textAreaElm.style.left = $event.clientX + 'px';

    // This does not appear to be necessary on tested browsers, but I feel better with it here!
    textAreaElm.style.opacity = '0';

    textAreaElm.value = msgToCopy;
    document.body.appendChild(textAreaElm);
    textAreaElm.focus();
    textAreaElm.select();
    try {
      var isSuccessful = document.execCommand('copy');
      if (!isSuccessful) throw new Error();
      this.toastService.showToast(successMsg, 'darkgreen');
    } catch (ignore) {
      this.toastService.showToast('Can\'t copy. Check your settings.', 'red');
    }
    document.body.removeChild(textAreaElm);
  }

}
