import { Injectable } from '@angular/core';

import { EndUsersService } from '@services/end-users.service';
import { ToastService } from '@services/toast.service';
import { VehicleListing } from '@models/vehicle-listing';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteVehiclesService {

  private _listingIdToIndexRecord: Record<number, number> = {};
  private _listings: VehicleListing[] = [];

  constructor(
    private endUsersService: EndUsersService,
    private toastService: ToastService,
  ) {
    endUsersService.favoriteVehicles$.subscribe(favoriteVehicles => {
      this._listings = favoriteVehicles;
      this.mapListingIDs();
    });
  }

  get listings(): VehicleListing[] {
    return this._listings;
  }

  get savedListingCount(): number {
    return this._listings.length;
  }

  isSaved(listing: VehicleListing): boolean {
    return this._listingIdToIndexRecord[listing.id] !== undefined;
  }

  remove(listing: VehicleListing) {
    const idx = this._listingIdToIndexRecord[listing.id];
    if (idx !== undefined) {
      this.endUsersService.favoriteVehicleRemove(listing.classifiedAdID).subscribe({
        next: () => this.toastService.showToast('Removed: <strong>' + listing.make + ' ' + listing.model + '</strong>', 'darkred'),
        error: this.httpErrorHandler,
      });
      this._listings.splice(idx, 1);
      this.mapListingIDs();
    }
  }

  save(listing: VehicleListing) {
    this.endUsersService.favoriteVehicleSave(listing.classifiedAdID).subscribe({
      next: () => this.toastService.showToast('Saved: <strong>' + listing.make + ' ' + listing.model + '</strong>', 'darkgreen'),
      error: this.httpErrorHandler,
    });
    this._listings.unshift(listing);
    this.mapListingIDs();
  }

  private mapListingIDs() {
    this._listingIdToIndexRecord = {};
    this._listings.forEach((listing, idx) => this._listingIdToIndexRecord[listing.id] = idx);
  }

  private httpErrorHandler() {
    window.parent.postMessage({eventType: 'alert', alertMessage: 'Unable to process request. Check your Internet connection and try again. If the problem persists, try again later.'}, environment.apiBaseUrl);
  }

}
