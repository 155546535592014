import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FavoriteVehiclesService } from '@services/favorite-vehicles.service';
import { UserSessionService } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';

@Component({
  selector: 'app-save-vehicle-button',
  templateUrl: './save-vehicle-button.component.html',
  styleUrls: ['./save-vehicle-button.component.sass']
})
export class SaveVehicleButtonComponent implements OnInit {

  @Input() listing!: VehicleListing;

  constructor(
    private favoriteVehiclesService: FavoriteVehiclesService,
    private router: Router,
    private userSessionService: UserSessionService,
  ) { }

  ngOnInit() {
  }

  isSavedVehicle() {
    return this.favoriteVehiclesService.isSaved(this.listing);
  }

  toggleSaveVehicle() {
    if (this.userSessionService.isLoggedIn) {
      if (this.isSavedVehicle()) {
        this.favoriteVehiclesService.remove(this.listing);
      } else {
        this.favoriteVehiclesService.save(this.listing);
      }
    } else {
      const state = {
        listingToSave: this.listing,
        msg: 'Please log in or create an account to save your favorite vehicles.',
      };
      this.router.navigate(['/login'], {state: state});
    }
  }

}
