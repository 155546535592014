<div *ngIf="dealerTags.isVirtualCarSaleParticipant; then showVirtualCarSaleBadge"></div>
<!-- <ng-container *ngTemplateOutlet="showVirtualCarSaleBadge"></ng-container> -->
<ng-template #showVirtualCarSaleBadge>

  <div class="dealer-tags-badge d-flex" [ngbTooltip]="configService.virtualCarSaleParticipantText">
    <img src="/assets/img/virtual-car-sale.png" alt="">
    <span>Participating<br>Dealer</span>
  </div>

</ng-template>

<div *ngIf="!dealerTags.isVirtualCarSaleParticipant && dealerTags.isPreferred; then showPreferredDealerBadge"></div>
<!-- <ng-container *ngTemplateOutlet="showPreferredDealerBadge"></ng-container> -->
<ng-template #showPreferredDealerBadge>

  <div class="dealer-tags-badge d-flex" [ngbTooltip]="configService.preferredDealerText">
    <img src="/assets/img/preferred-dealer.png" alt="">
    <span>Preferred<br>Dealer</span>
  </div>

</ng-template>