export class VehicleListing {
  id!: number;
  isNew!: boolean;
  year!: number | undefined;
  vehicleType!: string;
  make!: string;
  model: string | undefined;
  bodyStyle: string | undefined;
  trim: string | undefined;
  mileage: number | null = null;
  location!: VehicleLocation;
  price!: number;
  vin!: string;
  cylinders!: string;
  drivetrain!: string;
  imageUrl!: string;
  priceRating!: string;
  daysOnMarket!: number;
  classifiedAdID!: string;
  dealerTags!: DealerTags;
  freeHistoryReport: string | undefined;
  products!: Array<ProtectionProduct>;
  customFilterMatches: string[] | undefined;
  moreDetail: VehicleDetail | undefined;
  seller!: VehicleSeller;
  [key: string]: any; // needed for dynamic property access in compare-vehicles component
}

export class VehicleLocation {
  city!: string;
  state!: string;
  distanceMiles!: number;
}

export class VehicleSeller {
  notes: string | undefined;
  phone: string | undefined;
  id: number | undefined;
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  promoMessage: string | undefined;
}

export class VehicleDetail {
  seller!: VehicleSeller;
  featureList!: string[];
  transmission: string | undefined;
  allImgUrls!: string[];
  interiorColor: string | undefined;
  exteriorColor: string | undefined;
  interiorColorNormalized: string | undefined;
  exteriorColorNormalized: string | undefined;
  fuelType: string | undefined;
  engine: string | undefined;
  cityMpg: number | undefined;
  hwyMpg: number | undefined;
  maxCargoCapacity: number | undefined;
  doorCount: number | undefined;
  isCpo: boolean | undefined;
  vtdUrl: string | undefined;
  averageDaysOnMarket: number | undefined;
  leadRequirements!: LeadRequirements;
  inventorySource!: InventorySource;
  prioritized!: boolean;
  [key: string]: any; // needed for dynamic property access in compare-vehicles component
}

export class LeadRequirements {
  phoneNumber!: LeadRequirement;
  zipCode!: LeadRequirement;
}

export type LeadRequirement = "IsRequired" | "IsOptional" | "IsNotApplicable";

// These should match the integer values used in the server-side JsonTransformers.scala
export enum InventorySource {
  // 0 used to be CarsDotComInventorySource
  // 1 used to be JDPowerInventorySource
  RollickInventorySource     = 2,
  MarketcheckInventorySource = 3,
  TrueCarInventorySource     = 4,
}

export interface FixedProductPrice {
  kind: "FixedProductPrice";
  amount: number;
}

export interface PrincipalBasedMonthlyRateProductPrice {
  kind: "PrincipalBasedMonthlyRateProductPrice";
  amountPerMillion: number;
}

export type ProductPrice = FixedProductPrice | PrincipalBasedMonthlyRateProductPrice;

// These strings should match the "name" parameters assigned to the ProductType case object types on the server-side
export type GAPType = "GAP";
export type DP1Type = "DP1";
export type DP2Type = "DP2";
export type VSCType = "VSC";

export type ProductType = GAPType | DP1Type | DP2Type | VSCType;

export interface ProtectionProduct {
  productType: ProductType;
  priceData: ProductPrice;
  blocks?: Array<ProductType>;
  description?: string;
}

export interface DealerTags {
  isPreferred: boolean;
  isVirtualCarSaleParticipant: boolean;
}

export interface CustomFilter {
  filterId: number;
  name: string;
  formLabel: string | null;
  badgeLabel: string;
  textColor: string;
  badgeBg: string;
  tooltip: string | null;
  clickUrl: string | null;
}
