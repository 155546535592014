<div class="l2-top-search bg-primary">
    <div class="l2-top-link l2-top-items">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M220-40q-24 0-42-18t-18-42v-655h60v655h487v60H220Zm120-120q-24 0-42-18t-18-42v-640q0-24 18-42t42-18h400q24 0 42 18t18 42v640q0 24-18 42t-42 18H340Zm0-120v60h400v-60H340Zm0-60h400v-400H340v400Zm70-92v-92.523Q410-542 420-551.5t27-9.5h131l-41-41 36-36 103 103-103 103-36-36 41-42H461v78h-51Zm-70-368h400v-60H340v60Zm0 0v-60 60Zm0 520v60-60Z"/></svg>
      <span class="l2-labels">
        <div (click)="copyLinkToListing($event)" class="copy-btn">
          Copy Link
        </div>
      </span>
    </div>
    <div class="l2-top-compare l2-top-items">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M422-40v-80H180q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h242v-80h60v880h-60ZM180-222h242v-277L180-222Zm362 102v-375l238 273v-558H542v-60h238q24 0 42 18t18 42v600q0 24-18 42t-42 18H542Z"/></svg>
      <span class="l2-labels">
        <div class="compare-btn" [ngClass]="{'compare-is-added': compareService.isAdded(listing)}">
          <div class="compare-btn-item d-flex">
            <span (click)="addToCompare()" class="compare-link d-flex">Compare<span *ngIf="compareService.listSize > 0" class="ml-2">({{compareService.listSize}})</span></span>
            <span (click)="addToCompare()" class="ml-2"><input type="checkbox" class="custom-image" [checked]="compareService.isAdded(listing)"><label></label></span>
          </div>
          <div class="compare-btn-item">
            <span class="small">
              <span *ngIf="compareService.listSize > 1" class="compare-go-link mt-2" ngbTooltip="Go to the vehicle comparison page" (click)="compare()">
                <div>Go</div>
                <div class="compare-go-arrow"><svg class="compare-go-arrow-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/></svg></div>
              </span>
            </span>
          </div>
        </div>
      </span>
    </div>
    <div class="l2-top-link l2-top-items">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M840-683v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h503l157 157Zm-60 27L656-780H180v600h600v-476ZM479.765-245Q523-245 553.5-275.265q30.5-30.264 30.5-73.5Q584-392 553.735-422.5q-30.264-30.5-73.5-30.5Q437-453 406.5-422.735q-30.5 30.264-30.5 73.5Q376-306 406.265-275.5q30.264 30.5 73.5 30.5ZM233-584h358v-143H233v143Zm-53-72v476-600 124Z"/></svg>
      <span class="l2-labels">
        <div><app-save-vehicle-button [listing]="listing"></app-save-vehicle-button></div>
      </span>
    </div>
    <!-- <div class="l2-top-coupon l2-top-items">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M140-277v97h680v-97H140Zm0-443h125q-5-9-8-22.5t-3-24.5q0-47.083 33-80.042Q320-880 366-880q30.741 0 56.87 15.5Q449-849 464-825l16.5 26 16.5-26q16-25 41.015-40t53.879-15Q640-880 673-847.5q33 32.5 33 80.861Q706-756 703.5-745t-7.5 25h124q24 0 42 18t18 42v480q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-480q0-24 18-42t42-18Zm0 336h680v-276H571l100 139-48 36-143-198-143 198-48-36 100-139H140v276Zm227-330q22 0 37.5-15.5T420-767q0-22-15.5-37.5T367-820q-22 0-37.5 15.5T314-767q0 22 15.5 37.5T367-714Zm225 0q22.95 0 38.475-15.5Q646-745 646-767t-15.525-37.5Q614.95-820 592-820q-21 0-36.5 15.5T540-767q0 22 15.5 37.5T592-714Z"/></svg>
      <span class="l2-labels-not-avail" *ngIf="!listing.dealerTags.isVirtualCarSaleParticipant"><span class="small">Coupon not Available</span></span>
      <span class="l2-labels" *ngIf="listing.dealerTags.isVirtualCarSaleParticipant">
        <a (click)="viewListing($event)" (auxclick)="viewListing($event)">Get Coupon</a>
      </span>
    </div> -->
    <div class="l2-top-dealer l2-top-items">
      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M481-29 346-160H160v-186L26-480l134-134v-186h186l135-134 133 134h186v186l134 134-134 134v186H614L481-29Zm0-452Zm0 368 107.917-107H740v-151l109-109-109-109v-151H589L481-849 371-740H220v151L111-480l109 109v151h150l111 107ZM363-310l117-71 117 71-31-133 104-90-137-11-53-126-53 126-137 11 104 90-31 133Z"/></svg>
      <span class="l2-labels-not-avail" *ngIf="!listing.dealerTags.isVirtualCarSaleParticipant"><span class="small">Not a Participating Dealer</span></span>
      <span class="l2-labels" *ngIf="listing.dealerTags.isVirtualCarSaleParticipant">
        <a (click)="viewListing($event)" (auxclick)="viewListing($event)">Participating Dealer</a>
      </span>
    </div>
</div>