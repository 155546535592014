import { Component, Input, OnInit } from "@angular/core";

import { ConfigService } from "@services/config.service";
import { VehicleListing } from "@models/vehicle-listing";

@Component({
  selector: 'app-vs-code',
  templateUrl: './vs-code.component.html',
  styleUrls: ['./vs-code.component.sass']
})
export class VSCodeComponent implements OnInit {

  @Input() listing!: VehicleListing;

  constructor( public configService: ConfigService ) {
  }

  ngOnInit() {
  }

}
