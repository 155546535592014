<div class="card" *ngIf="favoriteVehiclesService.savedListingCount > 0">
  <div class="card-body pb-0">
    <h4 class="card-title">Favorite Vehicles</h4>
    <div class="d-flex flex-wrap">
      <div *ngFor="let listing of getDisplayListings()" class="saved-vehicle text-center d-flex flex-column justify-content-between" (click)="viewListing($event, listing.classifiedAdID)" (auxclick)="viewListing($event, listing.classifiedAdID)">
        <img src="/assets/img/trash.png" alt="Remove this listing" class="trash" (click)="favoriteVehiclesService.remove(listing)">
        <div>
          <app-image-slider [imageUrl]="listing.imageUrl" [small]="true"></app-image-slider>
          <strong>{{listing.year}} {{listing.make}} {{listing.model}}</strong>
        </div>
        <div class="text-muted">{{listing.price | currency:'USD':digitsInfo:'1.0-0'}}</div>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <button class="btn btn-outline-secondary" (click)="showAll = !showAll" *ngIf="someAreHidden()">Show {{showAll ? 'Less' : 'All'}}</button>
        <button class="btn btn-outline-secondary" (click)="compare()" *ngIf="favoriteVehiclesService.savedListingCount > 1">Compare All</button>
      </div>
    </div>
  </div>
</div>