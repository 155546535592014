import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@services/config.service';
import { ToastService } from '@services/toast.service';
import { VehicleListing } from '@models/vehicle-listing';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompareService {

  private vehiclesToCompare: Map<string, VehicleListing> = new Map();

  private readonly compareVehiclesUrl = environment.apiBaseUrl + '/v2/cbs/:cu/compareVehicles';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private toastService: ToastService,
  ) { }

  get listSize() {
    return this.vehiclesToCompare.size;
  }

  get classifiedAdIDs() {
    return Array.from(this.vehiclesToCompare.keys()).toString();
  }

  getListings(classifiedAdIDs: string[]) {
    return this.http.post<VehicleListing[]>(this.compareVehiclesUrl.replace(':cu', this.configService.cuShortName), classifiedAdIDs);
  }

  isAdded(listing: VehicleListing) {
    return this.vehiclesToCompare.has(listing.classifiedAdID)
  }

  toggleVehicle(listing: VehicleListing) {
    if (this.vehiclesToCompare.has(listing.classifiedAdID)) {
      this.vehiclesToCompare.delete(listing.classifiedAdID);
      this.toastService.showToast('<strong>' + listing.make + ' ' + listing.model + '</strong> removed', 'darkblue');
    } else {
      this.vehiclesToCompare.set(listing.classifiedAdID, listing);
      this.toastService.showToast('<strong>' + listing.make + ' ' + listing.model + '</strong> added', 'darkblue');
    }
  }

  removeVehicle(listing: VehicleListing) {
    if (this.vehiclesToCompare.has(listing.classifiedAdID)) {
      this.vehiclesToCompare.delete(listing.classifiedAdID);
      this.toastService.showToast('<strong>' + listing.make + ' ' + listing.model + '</strong> removed', 'darkblue');
    }
  }

}
