import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsyncSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ConfigService } from '@services/config.service';
import { Residuals } from '@models/residuals';
import { VehicleListing } from '@models/vehicle-listing';

@Injectable({
  providedIn: 'root'
})
export class AFGService {

  readonly DEFAULT_MILEAGE = "12000"; // "12000" should match value used in scala AFG class
  readonly DEFAULT_PROGRAM_NAME = "Balloon"; // if no program name is available from the server, we will default to this

  private readonly currentYear = new Date().getFullYear();
  private readonly batchResidualsUrl = environment.apiBaseUrl + '/v4/cbs/:cu/data';

  // Residuals are retrieved in batch periodically and cached here.
  // The key to this map is a string with the format "VIN-Odometer".
  // A null result in the Subject indicates that the residuals have been requested from the server, but 
  // the vehicle is not eligible for quoting online.
  private readonly cachedResiduals: Map<String, AsyncSubject<Residuals | null>> = new Map();
  // This is used to store VIN-odometer pairs that need to be sent to the server next time 
  private readonly batchResidualQueue: Array<Array<string | number>> = new Array();

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) { }

  get programNameIndefiniteArticle(): string {
    const programNameFirstLetter = this.programName[0];
    if (programNameFirstLetter) {
      const lower = programNameFirstLetter.toLowerCase();
      if (lower === "a" || lower === "e" || lower === "i" || lower === "o" || lower === "u") {
        return "an";
      }
    }
    return "a";
  }

  get programName(): string {
    if (this.configService.balloonLoanProgramName) {
      if (this.configService.balloonLoanProgramName.indexOf(" Loan") !== -1) {
        this.configService.balloonLoanProgramName = this.configService.balloonLoanProgramName.replace(" Loan", "");
      }
      return this.configService.balloonLoanProgramName;
    } else {
      return this.DEFAULT_PROGRAM_NAME;
    }
  }

  private checkForNullResiduals(residuals: Residuals): boolean {
    const keys = [
      'residual12mo12000miles', 'hpvs12mo12000miles',
      'residual24mo12000miles', 'hpvs24mo12000miles',
      'residual36mo12000miles', 'hpvs36mo12000miles',
      'residual48mo12000miles', 'hpvs48mo12000miles',
      'residual60mo12000miles', 'hpvs60mo12000miles',
      'residual72mo12000miles', 'hpvs72mo12000miles',
      'residual84mo12000miles', 'hpvs84mo12000miles',
      'residual12mo15000miles', 'hpvs12mo15000miles',
      'residual24mo15000miles', 'hpvs24mo15000miles',
      'residual36mo15000miles', 'hpvs36mo15000miles',
      'residual48mo15000miles', 'hpvs48mo15000miles',
      'residual60mo15000miles', 'hpvs60mo15000miles',
      'residual72mo15000miles', 'hpvs72mo15000miles',
      'residual84mo15000miles', 'hpvs84mo15000miles',
      'residual12mo18000miles', 'hpvs12mo18000miles',
      'residual24mo18000miles', 'hpvs24mo18000miles',
      'residual36mo18000miles', 'hpvs36mo18000miles',
      'residual48mo18000miles', 'hpvs48mo18000miles',
      'residual60mo18000miles', 'hpvs60mo18000miles',
      'residual72mo18000miles', 'hpvs72mo18000miles',
      'residual84mo18000miles', 'hpvs84mo18000miles'
    ];
    return keys.every(key => residuals[key] === null);
  }

  getResiduals$(listing: VehicleListing): Observable<Residuals | null> {
    // Determine the mileage value for the vehicle listing
    // listing.mileage can be either null or a number
    const mileage = (() => {
      if (listing.mileage === null) {
        if (listing.isNew) {
          // For new vehicles with null mileage, use 0
          return 0;
        } else {
          // For used vehicles with null mileage, use -1 to force a lack of matching residual values
          return -1;
        }
      } else {
        // If mileage is provided, use it directly
        return listing.mileage;
      }
    })();

    // If mileage is -1 (see above), then we will not attempt to get residuals from the server
    if (mileage === -1) {
      return of(null);
    }

    const cacheKey = this.generateResidualsCacheKey(listing.vin, mileage);
    const cachedResidual$ = this.cachedResiduals.get(cacheKey);

    if (cachedResidual$ === undefined) {
      // If undefined, that means we have never encountered this VIN/odometer pair before.
      // In this case, we will add it to the queue for requesting from the server.
      const residuals$ = new AsyncSubject<Residuals | null>();
      this.cachedResiduals.set(cacheKey, residuals$);

      // If we have not yet queued a call to get the residuals from the server, do so now
      // We apply a delay so that multiple VIN/odometer pairs can be sent as a batch
      if (this.batchResidualQueue.length === 0) {
        setTimeout(() => this.getBatchResiduals(), 50);
      }

      // Add this VIN/odometer pair to the queue
      this.batchResidualQueue.push([listing.vin, mileage]);

      // Return the observable so the caller can get the residuals when they are ready
      return residuals$.pipe(
        map(residuals => {
          // Check if the residuals object is null or all relevant fields are null
          return residuals && !this.checkForNullResiduals(residuals) ? residuals : null;
        })
      );
    } else {
      // We have already either requested the residuals from the server or queued up a request.
      // Either way, we return the observable to the caller so they can get the result.
      return cachedResidual$.pipe(
        map(residuals => {
          // Check if the residuals object is null or all relevant fields are null
          return residuals && !this.checkForNullResiduals(residuals) ? residuals : null;
        })
      );
    }
  }

  // Checks if this listing is plausibly eligible for a balloon loan, based on the type, mileage, and model year.
  // This does not check if we have residual values available, which is likely the final determinate of eligibility.
  // This funciton does NOT require a hit to the server to execute.
  mayBeEligible(listing: VehicleListing, zeroPriceIsEligible: boolean = false): boolean {
    // If no balloon loans are available, then all vehicles are not eligible
    if (!this.configService.balloonLoanProgramName)
      return false;

    // If the vehicle isn't a car, it's not eligible
    if (listing.vehicleType != 'Auto')
      return false;

    // If the price is 0, this listing is not eligible (unless the caller explicitly considers zero priced listings as potentially eligible)
    if (listing.price === 0 && !zeroPriceIsEligible)
      return false;

    // Should match year / mileage logic in scala AFG.isEligible
    if (listing.isNew) {
      // New listings are always eligible because they should never have many miles.
      // We account for this explicitly here because some listings have a null mileage
      // which would fail the tests below.
      return true;
    } else {
      if (listing.mileage === null) {
        return false;
      } else {
        if (listing.year === undefined) {
          return false;
        } else {
          if (listing.year >= this.currentYear) {
            return listing.mileage <= 30000;
          } else if (listing.year == this.currentYear - 1) {
            return listing.mileage <= 45000;
          } else if (listing.year == this.currentYear - 2) {
            return listing.mileage <= 60000;
          } else if (listing.year == this.currentYear - 3) {
            return listing.mileage <= 75000;
          } else if (listing.year == this.currentYear - 4) {
            return listing.mileage <= 90000;
          } else if (listing.year == this.currentYear - 5) {
            return listing.mileage <= 105000;
          } else {
            return false;
          }
        }
      }
    }
  }

  // Checks if the listing is eligible for a balloon loan by checking with the server to see if we have residual values.
  // This is more accurate than mayBeEligible but requires a network request.
  isAfgEligible(listing: VehicleListing): Observable<boolean> {
    if (!this.mayBeEligible(listing, true)) {
      // If the type, model year, or mileage excludes this vehicle, we do not need to check with the server.
      // Indeed, checking with the server in that case might be misleading, because the server could have old residual values
      // from before this vehicle became ineligible (e.g., if it became ineligible because the current year changed).
      return of(false);
    } else if (listing.mileage === null && !listing.isNew) {
      return of(false);
    } else {
      return this.getResiduals$(listing).pipe(
        map((residuals) => residuals !== null)
      );
    }
  }  

  private generateResidualsCacheKey(vin: string, mileage: number): string {
    return vin + '-' + mileage;
  }

  private getBatchResiduals() {
    // If there are any VIN/odometers that are queued up for submission to the server in order to get residuals,
    // then process the queue. Otherwise, do nothing.
    if (this.batchResidualQueue.length > 0) {
      // We will send VIN/odometer pairs to the server. The server will send back residuals for the VIN/odometer pairs
      // which are eligible for balloon loan payment quoting online. If a VIN/odometer pair that we submit is not eligible,
      // it will not be included in the returned data from the server. We need to track which pairs we submit so we can determine
      // which pairs, if any, are left off of the returned data. (batchResidualQueue will be cleared out as soon as we submit
      // the data to the server below, so we do not use that to track what we send.)
      const submittedResiduals = [...this.batchResidualQueue]; // Copy the queue into a new array

      this.http.post<Residuals[]>(this.batchResidualsUrl.replace(':cu', this.configService.cuShortName), this.batchResidualQueue)
        .subscribe((residualsArray) => {
          // We will record all the VIN/odometer pairs that the server sends back here.
          // Then we can check which pairs are in submittedResiduals but not in receivedResiduals
          // to determine which ones the server did not provide data for.
          const receivedResiduals = new Map<string, boolean>();

          residualsArray.forEach((residuals) => {
            const cacheKey = this.generateResidualsCacheKey(residuals.vin, residuals.mileage);
            const residuals$ = this.cachedResiduals.get(cacheKey);
            if (residuals$ !== undefined) {
              // Deliver the data to the observable that has been waiting for it
              residuals$.next(residuals);
              residuals$.complete();
            }

            // Record the fact that we did receive back data for this VIN/odometer pair
            receivedResiduals.set(cacheKey, true);
          });

          // Now determine which VIN/odometer pairs we submitted but did not get back.
          // We still need to trigger a .next() and .complete() call on the observable for those pairs,
          // so that the UI code can know that the vehicle is not eligible for online quoting.
          submittedResiduals.forEach((vinOdometerPair) => {
            const vin = vinOdometerPair[0] as string;
            const mileage = vinOdometerPair[1] as number;
            const cacheKey = this.generateResidualsCacheKey(vin, mileage);
            if (!receivedResiduals.has(cacheKey)) {
              const residuals$ = this.cachedResiduals.get(cacheKey);
              if (residuals$ !== undefined) {
                residuals$.next(null);
                residuals$.complete();
              }
            }
          });
        });

      // Empty the queue to indicate that there are no residuals that have not yet been sent to the server
      this.batchResidualQueue.length = 0;
    }
  }

}
