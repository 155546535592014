<ng-container *ngFor="let customFilterName of listing.customFilterMatches; let i = index">
    <div
      *ngIf="configService.customFilters[customFilterName] as customFilter"
      [ngClass]="{'custom-filter-badge d-flex': true, 'clickable': customFilter.clickUrl !== null}"
      [style.color]="customFilter.textColor"
      [style.background]="customFilter.badgeBg"
      [style.top]="(2.75 * (i + 1)) + 'em'"
      [ngbTooltip]="customFilter.tooltip"
      (click)="clickBadge(customFilter)"
      [innerHTML]="customFilter.badgeLabel"
    ></div>
</ng-container>
