import { Component, Input, OnInit } from '@angular/core';
import { CustomFilter, VehicleListing } from '@models/vehicle-listing';
import { ConfigService } from '@services/config.service';

@Component({
  selector: 'app-custom-filter-badges',
  templateUrl: './custom-filter-badges.component.html',
  styleUrls: ['./custom-filter-badges.component.sass']
})
export class CustomFilterBadgesComponent implements OnInit {

  @Input() listing!: VehicleListing;

  constructor(
    public configService: ConfigService
  ) { }

  ngOnInit() {
  }

  clickBadge(customFilter: CustomFilter) {
    if (customFilter.clickUrl) {
      window.open(customFilter.clickUrl, "_blank");
    }
  }

}
