import { Component, Input, OnInit } from '@angular/core';

import { ConfigService } from '@services/config.service';
import { UserSessionService } from '@services/user-session.service';
import { InventorySource, VehicleListing } from '@models/vehicle-listing';

@Component({
  selector: 'app-no-financing-notice',
  templateUrl: './no-financing-notice.component.html',
  styleUrls: ['./no-financing-notice.component.sass']
})
export class NoFinancingNoticeComponent implements OnInit {

  @Input() listing!: VehicleListing;

  constructor(
    public configService: ConfigService,
    public userSessionService: UserSessionService,
  ) { }

  ngOnInit() {
  }

  getArticleForWord(word: string) {
    const prettyWord = this.prettyVehicleType(word);
    if (prettyWord.length > 0) {
      const trimmed = prettyWord.trim().toLowerCase();
      if (trimmed === "rv") {
        return 'an';
      } else {
        const firstLetter = trimmed[0];
        switch (firstLetter) {
          case 'a':
          case 'e':
          case 'i':
          case 'o':
          case 'u':
            return 'an';
          default:
            return 'a';
        }
      }
    } else {
      return '';
    }
  }

  prettyVehicleType(vehicleType: string) {
    const lower = vehicleType.toLowerCase();
    switch (lower) {
      case 'marine':
      case 'powersport':
        return lower + ' vehicle';
      case 'rv':
        return 'RV';
      case 'auto':
        return 'vehicle';
      default:
        return lower;
    }
  }

}
