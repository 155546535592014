import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConnectableObservable, Observable, Subject } from 'rxjs';
import { multicast } from 'rxjs/operators';

import { ConfigService } from '@services/config.service';
import { environment } from '@environments/environment';
import { UserSessionService } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';

export interface FindBetterListingsSearch {
  selectedVehicle: VehicleListing,
  locationRestriction: string,
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  srpAttachRequest$ = new Subject<void>();

  private readonly findBetterUrl  = environment.apiBaseUrl + '/v2/cbs/:cu/findBetter/:id;:zip;:t';
  private readonly listingUrl     = environment.apiBaseUrl + '/v2/cbs/:cu/listing/:id;:zip';
  private readonly makesModelsUrl = environment.apiBaseUrl + '/v3/cbs/:cu/makesModels';
  private readonly searchUrl      = environment.apiBaseUrl + '/v2/cbs/:cu/findVehicles';
  private readonly countUrl       = environment.apiBaseUrl + '/v2/cbs/:cu/countVehicles';

  private mostRecentResults: VehicleListing[] = [];

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private router: Router,
    private userSessionService: UserSessionService,
  ) { }

  getListing(classifiedAdID: string): Observable<VehicleListing> {
    return this.http.get<VehicleListing>(
      this.listingUrl.replace(':id', classifiedAdID)
                     .replace(':cu', this.configService.cuShortName)
                     .replace(':zip', this.userSessionService.zipCode)
    );
  }

  getMakesModels(): Observable<{ [key: string]: { [key: string]: { [key: string]: string[] } } }> {
    return this.http.get<{ [key: string]: { [key: string]: { [key: string]: string[] } } }>(
      this.makesModelsUrl.replace(':cu', this.configService.cuShortName)
    );
  }

  getNextListing(currentListingId: number): VehicleListing {
    return this.getListingFromCache(currentListingId, 1);
  }

  getPrevListing(currentListingId: number): VehicleListing {
    return this.getListingFromCache(currentListingId, -1);
  }

  findBetterListings(search: FindBetterListingsSearch) {
    return this.requestAndCacheListings(() => {
      return this.http.get<VehicleListing[]>(
        this.findBetterUrl.replace(':id', search.selectedVehicle.id.toString())
                          .replace(':cu', this.configService.cuShortName)
                          .replace(':zip', this.userSessionService.zipCode)
                          .replace(':t', search.locationRestriction)
      );
    });
  }

  findListings(searchParams: Object) {
    return this.requestAndCacheListings(() => this.http.post<VehicleListing[]>(this.searchUrl.replace(':cu', this.configService.cuShortName), searchParams));
  }

  countListings(searchParams: Object) {
    return this.http.post<number>(this.countUrl.replace(':cu', this.configService.cuShortName), searchParams);
  }

  goToSRP() {
    // Request the SRP attach itself again
    this.router.navigate(['/search']); // needed in case we never visited the SRP before
    this.srpAttachRequest$.next(); // to restore scroll position and last search parameters
  }

  private requestAndCacheListings(requestCallback: () => Observable<VehicleListing[]>) {
    // We want to multicast the results of the http.post call so that we can cache them here inside the SearchService.
    // To multicast the http.post, we have to turn the Observable returned by the post call into a Subject.

    // Create the Observable for the http.post call
    const results$ = requestCallback();

    // Create the Subject which will do the multicasting
    const subject$ = new Subject<VehicleListing[]>();

    // Pipe the results from the http.post into the Subject
    const multicasted$ = results$.pipe(multicast(subject$)) as ConnectableObservable<VehicleListing[]>;

    // Subscribe here within SearchService and watch for results to come in
    multicasted$.subscribe(results => this.mostRecentResults = results);

    // Trigger the actual POST request
    multicasted$.connect();

    // Return the multicasting Observable for the caller to subscribe to
    return multicasted$;
  }

  private getListingFromCache(currentListingId: number, incrementor: number): VehicleListing {
    const currentListingIndex = this.mostRecentResults.findIndex(listing => listing.id === currentListingId);
    return this.mostRecentResults[currentListingIndex + incrementor];
  }

}
