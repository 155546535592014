export namespace PhoneFormatter {
  
  export function format(phone: string) {
    const stripNonDigits = phone.replace(/\D/g, '');

    let formatted = '';
    if (stripNonDigits.length == 3) {
      formatted = '(' + stripNonDigits.substr(0, 3) + ') ';
    } else if (stripNonDigits.length == 6) {
      formatted = '(' + stripNonDigits.substr(0, 3) + ') ' + stripNonDigits.substr(3, 3) + '-';
    } else if (stripNonDigits.length == 10) {
      formatted = '(' + stripNonDigits.substr(0, 3) + ') ' + stripNonDigits.substr(3, 3) + '-' + stripNonDigits.substr(6, 4);
    } else if (stripNonDigits.length > 10) {
      // If we have a phone number longer than 10 characters, there is a country code
      const countryCodeLength = stripNonDigits.length - 10;
      const countryCode = stripNonDigits.substr(0, countryCodeLength);
      const areaCode = stripNonDigits.substr(countryCodeLength, 3);
      const rest =
        stripNonDigits.substr(countryCodeLength + 3, 3) +
        "-" +
        stripNonDigits.substr(countryCodeLength + 6, 4);
      formatted = countryCode + " (" + areaCode + ") " + rest;
    }

    return formatted;
  }

}