import { Component, OnInit, Input } from "@angular/core";

import { ConfigService } from "@services/config.service";

@Component({
  selector: 'app-afg-badge',
  templateUrl: './afg-badge.component.html',
  styleUrls: ['./afg-badge.component.sass']
})
export class AfgBadgeComponent {

  @Input() vcsEnabled: boolean = false;

  constructor(public configService: ConfigService) {}

  ngOnInit(): void {}

}
