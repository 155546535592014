<footer class="text-justify small p-5">
  <div class="container-lg">
	  <p *ngIf="configService.virtualCarSaleConfig?.disclaimer">{{ configService.virtualCarSaleConfig?.disclaimer }}</p>
	  
	  <p>This site is provided as a research tool for our members' convenience. However, the vehicle listings are provided by dealers in our network, not by the credit union, and the credit union does not guarantee the accuracy of the listing description and price or the quality and safety of any vehicle listed. Please contact the dealer providing the listing to answer specific questions about any particular vehicle.</p>

	  <p>The information provided is for illustrative purposes only and is not an offer to lend. Consumer must qualify for credit from credit union in order to obtain financing. See your credit union for details including actual offers, if any, that may be available to you.

	  While we wish we could provide a perfectly accurate monthly payment estimate on this page, there are many factors which we cannot practically take into account here. Because of this, it should be understood that the actual monthly payment amount may vary (perhaps significantly) from the illustrative amount. We encourage our members to discuss their financing options with a loan officer in order to get the most accurate monthly payment estimate.

	  The tax, title, license, finance charges, and other fees are an assumed estimate for illustrative purposes. The actual values may vary from the assumed values depending on your state, which vehicle you purchase, and your credit union's policies. Some states apply sales tax to the full purchase price of the car, regardless of the value of the trade-in. Check with your state taxing authority to determine rules for your state. The loan terms used are not guaranteed to be available to you or for this specific vehicle. Older vehicles may not necessarily qualify for the interest rate cited above.</p>
	</div>
</footer>