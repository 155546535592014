<div
  *ngIf="listing.dealerTags.isVirtualCarSaleParticipant"
  class="l2-search-vs-coupon"
>
  <div class="l2-vs-coupon">
    <a
      target="_blank"
      rel="noopener noreferrer"
      style="white-space: nowrap;"
      *ngIf="configService.virtualCarSaleConfig?.learnMoreURL"
      [href]="configService.virtualCarSaleConfig?.learnMoreURL"
    >
      <img src="/assets/img/virtual-car-sale-coupon.png">
      <p class="l2-vs-coupon-l1 text-uppercase">20% off</p>
      <p class="l2-vs-coupon-l2 text-uppercase">Discount</p>
      <p class="l2-vs-coupon-l3 text-uppercase">Buy Now</p>
    </a>
  </div>
</div>