import { Component, Input, OnInit } from "@angular/core";
import { ConfigService } from "@services/config.service";
import { DealerTags } from "@models/vehicle-listing";

/**
 * Component for both the preferred dealer badge and the virtual car sale participant badge.
 * The virtual car sale badge takes precedent over the preferred dealer badge.
 * Note that this doesn't require the virtualCarSaleConfig because the search will
 * check for an active virtual car sale itself.
 *
 * Takes @param dealerTags as input, from a listing.
 */
@Component({
  selector: "app-dealer-tags-badge",
  templateUrl: "./dealer-tags-badge.component.html",
  styleUrls: ["./dealer-tags-badge.component.sass"],
})
export class DealerTagsBadgeComponent implements OnInit {
  @Input() dealerTags!: DealerTags;

  constructor( public configService: ConfigService ) {
    //console.log("The Virtual Car SAle Participant", this.dealerTags.isVirtualCarSaleParticipant);
  }

  ngOnInit(): void {
  }
}
